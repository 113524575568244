import { makeAutoObservable, runInAction } from 'mobx'

class Paginator {
  constructor(count = 0, total = 0, perPage = 10, currentPage = 1, totalPages = 1) {
    this.count = count
    this.total = total
    this.perPage = perPage
    this.currentPage = currentPage
    this.totalPages = totalPages

    makeAutoObservable(this)
  }

  setCurrentPage(currentPage) {
    runInAction(() => {
      this.currentPage = currentPage
    })
  }

  setTotal(total) {
    runInAction(() => {
      this.total = total
    })
  }

  setTotalPages(lastPage) {
    runInAction(() => {
      this.totalPages = lastPage
    })
  }

  setPerPage(perPage) {
    runInAction(() => {
      this.perPage = perPage
      this.count = perPage
    })
  }

  // eslint-disable-next-line camelcase
  static fromJson({ per_page, current_page, total, last_page }) {
    return new Paginator(per_page, total, per_page, current_page, last_page)
  }

  static empty() {
    return new Paginator(0, 0, 10, 1, 1)
  }
}

export default Paginator
