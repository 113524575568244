import React from 'react'
import c from 'classnames'
import PropTypes from 'prop-types'
import styles from './logoutIcon.module.scss'

const LogoutIcon = ({ className, size }) => {
  return (
    <svg
      className={c(styles.icon, className)}
      width={size}
      viewBox="0 -1 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 13.125C10.2569 13.125 12.5 10.8819 12.5 8.12501C12.5 6.03126 11.205 4.23688 9.375 3.49313V4.88251C9.94432 5.21121 10.4172 5.6838 10.7463 6.2529C11.0754 6.822 11.2491 7.46761 11.25 8.12501C11.25 10.1931 9.56813 11.875 7.5 11.875C5.43187 11.875 3.75 10.1931 3.75 8.12501C3.75083 7.4676 3.9245 6.82196 4.25359 6.25285C4.58269 5.68374 5.05563 5.21116 5.625 4.88251V3.49313C3.795 4.23688 2.5 6.03126 2.5 8.12501C2.5 10.8819 4.74313 13.125 7.5 13.125Z"
        fill="#454545"
      />
      <path d="M6.875 1.25H8.125V7.5H6.875V1.25Z" fill="#454545" />
    </svg>
  )
}

LogoutIcon.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number,
}

LogoutIcon.defaultProps = {
  className: null,
  size: 20,
}

export default LogoutIcon
