import { AuthUser as AuthUserSubFramework } from 'subFramework'
import Tenant from 'models/Tenant'

class AuthUser extends AuthUserSubFramework {
  constructor(id, email, token, tenant, name, username) {
    super(id, email, token)

    this.tenant = tenant
    this.name = name
    this.username = username
  }

  setToken(token) {
    this.token = token
  }

  setTenant(tenant) {
    this.tenant = tenant
  }

  setPermissions(permissions) {
    permissions.forEach((permission) => this.addPermission(permission))
  }

  static fromJson({ accessToken, user = {}, tenant = user?.tenant }) {
    const { id, email = null, name = null, username = null, permissions = [] } = user
    const authUser = new AuthUser(
      id,
      email,
      accessToken,
      tenant ? Tenant.fromJson(tenant) : null,
      name,
      username
    )

    permissions.forEach((permission) => authUser.addPermission(permission.name))

    return authUser
  }

  static fromCookie({ userId, email, token, name, tenant, username, permissions }) {
    const authUser = new AuthUser(
      userId,
      email,
      token,
      tenant ? Tenant.fromJson({ ...tenant }) : null,
      name,
      username
    )

    permissions.forEach((permission) => authUser.addPermission(permission))

    return authUser
  }
}

export default AuthUser
