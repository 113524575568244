import React from 'react'
import c from 'classnames'
import PropTypes from 'prop-types'
import styles from './taskListIcon.module.scss'

const TaskListIcon = ({ className, size }) => {
  return (
    <svg
      className={c(styles.icon, className)}
      width={size}
      viewBox="-1 -2 23 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.85185 6.62493H15M3 6.47678L3.59259 7.06937L5.07407 5.58789M3 10.9212L3.59259 11.5138L5.07407 10.0323M3 15.3657L3.59259 15.9583L5.07407 14.4768M6.85185 11.0694H15M6.85185 15.5138H15"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

TaskListIcon.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number,
}

TaskListIcon.defaultProps = {
  className: null,
  size: 20,
}

export default TaskListIcon
