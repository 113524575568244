class SelectOption {
  constructor(id, value) {
    this.id = id
    this.value = value
  }

  static fromJson({ name, label }) {
    return new SelectOption(name, label)
  }
}

export default SelectOption
