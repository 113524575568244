import { memo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { DASHBOARD } from 'routing/paths'
// import desktopLogo from 'assets/logo.svg'
import mobileLogo from 'assets/compact-logo.svg'
import MenuIcon from 'mdi-react/MenuIcon'
// import ProfileActions from 'presentation/ProfileActions'
import styles from './authorizedHeader.module.scss'

const AuthorizedHeader = ({ handleClickMenu }) => {
  const { t } = useTranslation('header')

  return (
    <div className={styles.headerContainer}>
      <div className={styles.headerIconsContainer}>
        <NavLink to={DASHBOARD} title="WitWot">
          {/* <img src={desktopLogo} className={styles.desktopLogo} alt="WitWot" /> */}
          <img src={mobileLogo} className={styles.mobileLogo} alt="WitWot" />
        </NavLink>
        <div>
          {/* <ProfileActions /> */}
          <MenuIcon
            className={styles.menuIcon}
            onClick={handleClickMenu}
            title={t('menu')}
            size={25}
          />
        </div>
      </div>
    </div>
  )
}

AuthorizedHeader.propTypes = {
  handleClickMenu: PropTypes.func.isRequired,
}

export default memo(AuthorizedHeader)
