import PropTypes from 'prop-types'
import { memo } from 'react'
import Select from 'react-select'
import { FormLabel } from 'subFramework'
import styles from './formSelect.module.scss'

const customStyles = (mergeStyles, error) => ({
  control: (provided, state) => {
    let borderColor = 'var(--input-border)'

    if (state.menuIsOpen) {
      borderColor = 'var(--input-border-focus)'
    }

    if (error) {
      borderColor = 'var(--error-color)'
    }

    if (state.isDisabled) {
      borderColor = 'var(--input-border-literal)'
    }

    return {
      ...provided,
      opacity: '1',
      borderColor,
      background: state.isDisabled ? 'var(--main-bg)' : 'var(--input-backgound)',
      borderRadius: 'var(--input-border-radius)',
      boxShadow: 'none',
      fontFamily: 'var(--main-font)',
      fontWeight: 'bold',
      minHeight: 'var(--input-height)',
      paddingBottom: '0',
      paddingTop: '0',
      paddingLeft: '15px',
      paddingRight: '5px',
      width: '100%',
      ':hover': {
        borderColor,
      },
      ':focus': {
        borderColor,
      },
      ...mergeStyles.control,
    }
  },
  valueContainer: (provided) => ({
    ...provided,
    minHeight: '38px',
    fontWeight: 'bold',
    padding: 0,
  }),
  placeholder: (provided) => ({
    ...provided,
    color: 'var(--lighter-text-color)',
    fontWeight: '400',
    fontSize: '14px',
    '@media (max-width: 992px)': {
      fontSize: '12px',
    },
  }),
  menu: (provided) => ({
    ...provided,
    boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.17)',
    borderRadius: '6px',
    position: 'absolute !important',
    overflow: 'hidden',
    width: '100%',
    zIndex: 13,
  }),
  option: (provided, state) => {
    return {
      ...provided,
      ':active': {
        backgroundColor: 'var(--form-select-hover-option-background)',
        color: 'var(--form-select-hover-option-color)',
      },
      color: state.isSelected
        ? 'var(--form-select-selected-option-color)'
        : 'var(--form-select-text-color)',
      // eslint-disable-next-line no-nested-ternary
      backgroundColor: state.isSelected
        ? 'var(--form-select-selected-option-background)'
        : state.isFocused
        ? 'var(--form-select-default-option-hover-background)'
        : 'var(--form-select-default-option-background)',
      fontSize: '14px',
    }
  },
  singleValue: (provided) => ({
    ...provided,
    color: 'var(--form-select-text-color)',
    fontWeight: 'bold',
    fontSize: '14px',
  }),
  multiValue: (provided, state) => ({
    ...provided,
    background: 'var(--form-select-multivalue-option-background)',
    borderRadius: '8px',
    paddingRight: state.isDisabled ? '10px' : '0',
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: 'var(--form-select-multivalue-option-color)',
    fontSize: '14px',
    height: '32px',
    padding: '0',
    paddingLeft: '10px',
    paddingRight: '3px',
    lineHeight: '32px',
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    display: state.isDisabled ? 'none' : 'flex',
    color: 'var(--form-select-multivalue-option-color)',
  }),
  input: (provided) => ({
    ...provided,
    color: 'var(--primary-color)',
    fontSize: '14px',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    display: state.isDisabled ? 'none' : 'flex',
  }),
  loadingIndicator: (provided) => ({
    ...provided,
    span: {
      backgroundColor: 'black',
    },
  }),
})

const FormSelect = ({
  formSelectAs: Component,
  inputRef,
  label,
  mergeStyles,
  disabled,
  error,
  literal,
  value,
  isMulti,
  options,
  ...props
}) => (
  <label className={styles.label}>
    {label && <FormLabel label={label} disabled={disabled} />}
    <Component
      options={options}
      styles={customStyles(mergeStyles, error)}
      ref={inputRef}
      isDisabled={disabled || literal}
      getOptionLabel={(option) => {
        // eslint-disable-next-line no-underscore-dangle
        if (option.__isNew__) {
          return option.label
        }

        return option.value
      }}
      getOptionValue={(option) => {
        return option.id
      }}
      value={value}
      isMulti={isMulti}
      {...props}
    />
  </label>
)

FormSelect.propTypes = {
  formSelectAs: PropTypes.oneOfType([PropTypes.any]),
  label: PropTypes.string,
  inputRef: PropTypes.func,
  mergeStyles: PropTypes.oneOfType([PropTypes.any]),
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  options: PropTypes.oneOfType([PropTypes.any]),
  disabled: PropTypes.bool,
  literal: PropTypes.bool,
  isMulti: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.arrayOf(PropTypes.any),
    PropTypes.string,
  ]),
}

FormSelect.defaultProps = {
  label: '',
  formSelectAs: Select,
  inputRef: null,
  options: [],
  mergeStyles: { control: {} },
  disabled: false,
  error: false,
  literal: false,
  isMulti: false,
  value: null,
}

export default memo(FormSelect)
