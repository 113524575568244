import { runInAction } from 'mobx'
import RootService from 'services/RootService'
import AsyncStore from './AsyncStore'

class RootStore extends AsyncStore {
  constructor(authStore, settingsStore, domain = null) {
    super()
    this.authStore = authStore
    this.domain = domain
    this.settingsStore = settingsStore
    this.rootService = new RootService()
    this.tenant = null
  }

  async getTenantData() {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      this.preRequest()

      try {
        const tenant = await this.rootService.tenantData()

        runInAction(() => {
          this.tenant = tenant

          this.onSuccessRequest()
          resolve()
        })
      } catch (e) {
        runInAction(() => {
          this.onErrorRequest(e)
          reject()
        })
      }
    })
  }
}

export default RootStore
