import c from 'classnames'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import DynamicTableColumn from 'subFramework/models/DynamicTableColumn'
import { generateUniqueId } from 'util/utils'
import styles from './dynamicTableStyles.module.scss'

const DynamicTableContent = ({ columns, gridStyle, isLoading, data, handleRowClick, clickRow }) => {
  const { t } = useTranslation(['common', 'access'])
  return data.map((item, i) => (
    <div
      className={c(
        styles.rowItem,
        styles.contentRow,
        clickRow && styles.hoverClickRow,
        isLoading && styles.contentRowLoading
      )}
      /* eslint-disable-next-line react/no-array-index-key */
      key={`row_${item?.id}_${i}`}
      style={gridStyle}
    >
      {columns.map(
        ({
          key,
          title,
          dataAccessor,
          customTitleAlt,
          actionsColumn,
          mainColumn,
          displayLabel,
          displayColumn,
          rightColumn,
        }) => {
          let value = null
          const fieldName = title
          let titleAlt = null

          if (!isLoading) {
            if (dataAccessor) {
              value = dataAccessor(item)
            } else {
              value = item[key]
            }

            titleAlt = typeof value === 'string' ? value : ''

            if (customTitleAlt) {
              titleAlt = customTitleAlt(item)
            }
          }

          return (
            <div
              className={c(
                styles.itemContainer,
                actionsColumn && styles.actionsColumn,
                mainColumn && styles.mainItem,
                !displayColumn && styles.hideItem,
                rightColumn && styles.rightItem
              )}
              onClick={(e) => {
                if (title !== t(`access:actions`)) {
                  handleRowClick(e, item)
                }
              }}
              key={`id_${generateUniqueId()}`}
              role="button"
              tabIndex={0}
            >
              <div className={c(styles.fieldName, !displayLabel && styles.hideLabel)}>
                {fieldName}
              </div>
              <div className={styles.item} title={titleAlt}>
                {isLoading || (value !== '' && value !== null) ? value : '-'}
              </div>
            </div>
          )
        }
      )}
    </div>
  ))
}

DynamicTableContent.propTypes = {
  data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])).isRequired,
  columns: PropTypes.arrayOf(PropTypes.instanceOf(DynamicTableColumn)),
  gridStyle: PropTypes.oneOfType([PropTypes.any]),
  isLoading: PropTypes.bool,
}

DynamicTableContent.defaultProps = {
  isLoading: false,
  columns: [],
  gridStyle: {},
}

export default memo(observer(DynamicTableContent))
