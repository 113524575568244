import c from 'classnames'
import CheckBoldIcon from 'mdi-react/CheckBoldIcon'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { memo } from 'react'
import styles from './checkBox.module.scss'

const CheckBox = ({
  label,
  checked,
  literal,
  emptyIcon,
  onClick,
  error,
  size,
  labelClassname,
  secondary,
}) => (
  <div
    onClick={!literal && onClick ? onClick : () => {}}
    className={c(
      styles.container,
      !label && styles.withoutMargin,
      literal && styles.literal,
      styles[size],
      secondary && styles.secondary
    )}
    role="button"
    tabIndex={0}
  >
    <div
      className={c(
        styles.checkContainer,
        checked && styles.checkedCheckContainer,
        error && styles.error
      )}
    >
      {checked ? (
        <span>
          <CheckBoldIcon className={checked ? styles.checkIcon : styles.hiddenIcon} size={12} />
        </span>
      ) : (
        <span className={styles.checkIcon}>{emptyIcon}</span>
      )}
    </div>
    {label && <div className={c(styles.label, labelClassname)}>{label}</div>}
  </div>
)

CheckBox.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  error: PropTypes.bool,
  labelClassname: PropTypes.string,
  secondary: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'md']),
  literal: PropTypes.bool,
  emptyIcon: PropTypes.node,
}

CheckBox.defaultProps = {
  error: false,
  label: null,
  labelClassname: null,
  emptyIcon: null,
  secondary: false,
  size: 'md',
  literal: false,
  checked: false,
}

export default memo(observer(CheckBox))
