import c from 'classnames'
import MagnifyIcon from 'mdi-react/MagnifyIcon'
import PropTypes from 'prop-types'
import { memo, useCallback, useState } from 'react'
import { FormLabel } from 'subFramework'
import styles from './searchInput.module.scss'

const handleInputChange = (e, onChange, onlyNumeric) => {
  if (onlyNumeric && !/^-?\d*[.]?\d{0,2}$/.test(e.target.value)) {
    return false
  }

  if (onlyNumeric && e.target.value * 1 > Number.MAX_SAFE_INTEGER - 1) {
    return false
  }

  onChange(e)

  return null
}

const SearchInput = ({
  label,
  inputRef,
  disabled,
  onChange,
  onlyNumeric,
  error,
  literal,
  value,
  expandable,
  size,
  className,
  placeholder,
  ...props
}) => {
  const [expanded, setExpanded] = useState(!expandable)
  const handleFocus = useCallback(() => setExpanded(true), [])
  const handleBlur = useCallback(() => {
    setExpanded(!expandable)
  }, [value])

  return (
    <label className={styles.label}>
      {label && <FormLabel label={label} disabled={disabled} />}
      <div
        className={c(
          styles.inputContainer,
          expanded && styles.expanded,
          !expandable && styles.notExpandable,
          styles[size]
        )}
      >
        <input
          className={c(
            styles.input,
            error && styles.error,
            className !== '' && className,
            literal && styles.literal
          )}
          placeholder={placeholder}
          onChange={(e) => handleInputChange(e, onChange, onlyNumeric)}
          disabled={disabled || literal}
          ref={inputRef}
          value={value}
          onFocus={handleFocus}
          onBlur={handleBlur}
          {...props}
        />
        <span>
          <MagnifyIcon size={20} className={styles.searchIcon} />
        </span>
      </div>
    </label>
  )
}

SearchInput.propTypes = {
  inputRef: PropTypes.oneOfType([PropTypes.any]),
  onlyNumeric: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  literal: PropTypes.bool,
  size: PropTypes.oneOf(['md']),
  label: PropTypes.string,
  error: PropTypes.bool,
  expandable: PropTypes.bool,
  placeholder: PropTypes.string,
}

SearchInput.defaultProps = {
  onlyNumeric: false,
  disabled: false,
  inputRef: null,
  onChange: null,
  literal: false,
  expandable: true,
  size: null,
  className: '',
  error: false,
  label: '',
  value: '',
  placeholder: '',
}

export default memo(SearchInput)
