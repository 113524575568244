import c from 'classnames'
import EyeOffOutlineIcon from 'mdi-react/EyeOffOutlineIcon'
import EyeOutlineIcon from 'mdi-react/EyeOutlineIcon'
import PropTypes from 'prop-types'
import { memo, useState } from 'react'
import { FormLabel } from 'subFramework'
import styles from './input.module.scss'

const handleInputChange = (e, onChange, onlyNumeric) => {
  if (onlyNumeric && !/^-?\d*[.]?\d{0,2}$/.test(e.target.value)) {
    return false
  }

  if (
    onlyNumeric &&
    (e.target.value * 1 > Number.MAX_SAFE_INTEGER - 1 || e.target.value?.length > 10)
  ) {
    return false
  }

  onChange(e)

  return null
}

const Input = ({
  label,
  inputRef,
  disabled,
  onChange,
  onlyNumeric,
  error,
  literal,
  value,
  className,
  eye,
  type,
  ...props
}) => {
  const [inputType, setInputType] = useState(type)

  const toggleInputType = () => {
    setInputType((prevType) => (prevType === 'password' ? 'text' : 'password'))
  }

  return (
    <label className={styles.label}>
      {label && <FormLabel label={label} disabled={disabled} />}
      <div className={c(styles.rowInput)}>
        <input
          className={c(
            styles.input,
            error && styles.error,
            className !== '' && className,
            literal && styles.literal
          )}
          onChange={(e) => handleInputChange(e, onChange, onlyNumeric)}
          disabled={disabled || literal}
          ref={inputRef}
          value={value}
          type={inputType} /* Cambia entre "text" y "password" */
          {...props}
        />
        {eye === 'password' && (
          <span className={styles.eyeIcon} onClick={toggleInputType} role="button" tabIndex={0}>
            {inputType === 'password' ? (
              <EyeOutlineIcon size={18} />
            ) : (
              <EyeOffOutlineIcon size={18} />
            )}
          </span>
        )}
      </div>
    </label>
  )
}

Input.propTypes = {
  inputRef: PropTypes.oneOfType([PropTypes.any]),
  onlyNumeric: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  literal: PropTypes.bool,
  label: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  eye: PropTypes.string,
  type: PropTypes.string,
}

Input.defaultProps = {
  onlyNumeric: false,
  disabled: false,
  inputRef: null,
  onChange: null,
  literal: false,
  className: '',
  error: false,
  label: '',
  value: '',
  eye: '',
  type: '',
}

export default memo(Input)
