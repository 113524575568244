import { makeAutoObservable, runInAction } from 'mobx'

const ASC = 'ASC'
const DESC = 'DESC'

class Sorter {
  constructor(sortField, sortSense) {
    this.sortField = sortField
    this.sortSense = sortSense

    makeAutoObservable(this)
  }

  setSort(sortField, sortSense) {
    runInAction(() => {
      this.sortField = sortField
      this.sortSense = sortSense
    })
  }

  changeSort(sortField) {
    let sortSense = ASC

    if (sortField === this.sortField && this.sortSense === ASC) {
      sortSense = DESC
    }
    runInAction(() => {
      this.sortField = sortField
      this.sortSense = sortSense
    })
  }

  get isASC() {
    return this.sortSense === ASC
  }

  get asJson() {
    if (this.sortField) {
      return !this.isASC ? `-${this.sortField}` : this.sortField
    }

    return null
  }

  static fromJson({ sortField, sortSense }) {
    return new Sorter(sortField, sortSense)
  }

  static empty() {
    return new Sorter('', '')
  }
}

export default Sorter
