import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { useContext, useEffect, useState } from 'react'
import { LoadingRing, StoreContext } from 'subFramework'

const PreLoaders = ({ children }) => {
  const [isReady, setIsReady] = useState(false)
  const rootStore = useContext(StoreContext)

  useEffect(() => {
    rootStore.getTenantData().then(() => {
      setIsReady(true)
    })
  }, [])

  if (!isReady) {
    return <LoadingRing center />
  }

  return children
}

PreLoaders.propTypes = {
  children: PropTypes.node.isRequired,
}

export default observer(PreLoaders)
