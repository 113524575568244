import React from 'react'
import c from 'classnames'
import PropTypes from 'prop-types'
import styles from './mapIcon.module.scss'

const MapIcon = ({ className, size }) => {
  return (
    <svg
      className={c(styles.icon, className)}
      width={size}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.375 16.203 8.578 14.86l-2.797 1.11a.499.499 0 0 1-.523-.016.513.513 0 0 1-.258-.469V6.766c0-.136.04-.256.117-.36a.718.718 0 0 1 .305-.234l2.844-1a1.083 1.083 0 0 1 .625 0L12.687 6.5l2.782-1.11a.536.536 0 0 1 .523.024.495.495 0 0 1 .258.461v8.828a.42.42 0 0 1-.117.297.733.733 0 0 1-.29.188L13 16.203a1.081 1.081 0 0 1-.625 0zm-.219-1.062V7.25L9.094 6.219v7.89l3.062 1.032zm.938 0 2.219-.735v-8l-2.22.844v7.89zm-7.156-.188 2.218-.844V6.22l-2.219.734v8zm7.156-7.703v7.89-7.89zM8.156 6.219v7.89-7.89z"
        fill="#454545"
      />
    </svg>
  )
}

MapIcon.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number,
}

MapIcon.defaultProps = {
  className: null,
  size: 20,
}

export default MapIcon
