import React, { useState, useCallback, useEffect } from 'react'
import c from 'classnames'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import SearchInput from 'presentation/SearchInput'
import { generateUniqueId } from 'util/utils'
import CheckBox from 'presentation/CheckBox'
import MinusIcon from 'mdi-react/MinusIcon'
import styles from './optionsBox.module.scss'
import OptionsBoxStore from './OptionsBoxStore'

const OptionsBox = ({ options, literal, hasError, onChange, selectedItems }) => {
  const { t } = useTranslation('permissions')
  const [optionsBoxStore] = useState(() => new OptionsBoxStore(options))
  const selectedIds = selectedItems.map((selectedItem) => selectedItem.id)

  const { searchValue, filteredGroupedOptions } = optionsBoxStore

  const handleSelect = (items) => {
    onChange(items)
  }

  const toggleGroup = useCallback(
    (group) => {
      optionsBoxStore.toggleGroupStatus(group)
      if (optionsBoxStore.groupStatus[group].value) {
        handleSelect(
          options.filter(
            (option) =>
              optionsBoxStore.optionsIdsByGroup(group).includes(option.id) ||
              selectedIds.includes(option.id)
          )
        )
      } else {
        handleSelect(
          options.filter(
            (option) =>
              !optionsBoxStore.optionsIdsByGroup(group).includes(option.id) &&
              selectedIds.includes(option.id)
          )
        )
      }
    },
    [options, selectedIds, optionsBoxStore]
  )

  const toggleItem = useCallback(
    (item) => {
      if (selectedIds.includes(item.id)) {
        handleSelect(selectedItems.filter((selectedItem) => selectedItem.id !== item.id))
      } else {
        handleSelect(selectedItems.concat([item]))
      }
    },
    [selectedItems]
  )

  const onChangeSearch = useCallback((e) => {
    optionsBoxStore.changeSearchValue(e.target.value)
  }, [])

  useEffect(() => {
    optionsBoxStore.setOptions(options)
  }, [options])

  return (
    <div className={c(styles.listWrapperItem, hasError && styles.fieldRequired)}>
      <SearchInput
        className={styles.searchInput}
        value={searchValue.value}
        expandable={false}
        placeholder={t('common:search')}
        size="md"
        onChange={onChangeSearch}
      />
      <div className={c(styles.listBox)}>
        {Object.keys(filteredGroupedOptions).length === 0 ? (
          <div className={styles.noResults}>{t('common:noResultsFound')}</div>
        ) : (
          <div className={styles.options}>
            {Object.keys(filteredGroupedOptions).map((key) => {
              return (
                <div key={generateUniqueId()}>
                  <CheckBox
                    secondary
                    emptyIcon={
                      optionsBoxStore.groupedOptions[key].filter((option) =>
                        selectedIds.includes(option.id)
                      ).length > 0 ? (
                        <MinusIcon size={10} />
                      ) : null
                    }
                    size="sm"
                    key={t(key)}
                    label={t(key)}
                    checked={
                      optionsBoxStore.groupStatus[key]?.value &&
                      optionsBoxStore.groupedOptions[key].filter((option) =>
                        selectedIds.includes(option.id)
                      ).length === optionsBoxStore.groupedOptions[key].length
                    }
                    literal={literal}
                    onClick={() => toggleGroup(key)}
                  />
                  <div className={styles.items}>
                    {filteredGroupedOptions[key].map((item) => (
                      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                      <CheckBox
                        size="sm"
                        key={item.id}
                        secondary
                        label={item.name}
                        labelClassname={styles.itemLabel}
                        onClick={() => toggleItem(item)}
                        literal={literal}
                        checked={selectedIds?.includes(item.id)}
                      />
                    ))}
                  </div>
                </div>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}

OptionsBox.propTypes = {
  options: PropTypes.oneOfType([PropTypes.array]).isRequired,
  selectedItems: PropTypes.oneOfType([PropTypes.array]),
  literal: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  hasError: PropTypes.bool.isRequired,
}

OptionsBox.defaultProps = {
  literal: false,
  selectedItems: [],
}

export default observer(OptionsBox)
