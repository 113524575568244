// ERRORS
export const FIELD_EMAIL_ERROR = 'fieldEmailError'
export const FIELD_REQUIRED_ERROR = 'fieldRequiredError'
export const FIELD_NUMERIC_ERROR = 'fieldNumericError'
export const FIELD_DECIMAL_WITH_POINT_ERROR = 'fieldDecimalWithPoint'
export const FIELD_THREEDECIMAL_WITH_POINT_ERROR = 'fieldThreeDecimalWithPoint'
export const FIELD_LATITUDE_ERROR = 'fieldLatitudeError'
export const FIELD_LONGITUDE_ERROR = 'fieldLongitudeError'

export const REGEX_LAT = /^(-?[1-8]?\d(?:\.\d{1,18})?|90(?:\.0{1,18})?)$/
export const REGEX_LNG = /^(-?(?:1[0-7]|[1-9])?\d(?:\.\d{1,18})?|180(?:\.0{1,18})?)$/
export const complexityRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{9,}$/
export const DATE_FORMAT = 'DD/MM/YYYY HH:mm'
export const SHORT_DATE_FORMAT = 'DD/MM HH:mm'
export const HOUR_DATE_FORMAT = 'HH:mm'

export const sequentialPatterns = [
  'abcdefghijklmnopqrstuvwxyz',
  'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
  '0123456789',
  'qwertyuiop',
  'asdfghjkl',
  'zxcvbnm',
  '1234567890',
  '0987654321',
]

export default {
  REGEX_LAT,
  REGEX_LNG,
  FIELD_EMAIL_ERROR,
  FIELD_REQUIRED_ERROR,
  FIELD_NUMERIC_ERROR,
  FIELD_DECIMAL_WITH_POINT_ERROR,
  FIELD_LATITUDE_ERROR,
  FIELD_LONGITUDE_ERROR,
  FIELD_THREEDECIMAL_WITH_POINT_ERROR,
}
