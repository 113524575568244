import LogoutIcon from 'assets/icons/LogoutIcon'
import c from 'classnames'
import * as Routes from 'routing/paths'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { StoreContext } from 'subFramework'
import { useContext } from 'react'
import { getInitials } from 'util/utils'
import { NavLink } from 'react-router-dom'
import * as permissions from 'routing/permissions'
import { VIEW_OPERATOR } from 'routing/paths'
import MenuButton from '../MenuButton/MenuButton'
import styles from './profileActions.module.scss'

const ProfileActions = ({ collapsed }) => {
  const { t } = useTranslation()
  const { authStore } = useContext(StoreContext)
  const { authUser } = authStore
  const hasPermissionToViewProfile = authStore.can(permissions.VIEW_OPERATOR)

  return (
    <div className={styles.profileContainer}>
      <div className={styles.navBarProfileInformation}>
        <div className={styles.profile}>
          {authUser.name && <span title={authUser.name}>{getInitials(authUser.name)}</span>}
        </div>
        {!collapsed && (
          <div className={styles.userInformation}>
            <div>{authUser.name}</div>
          </div>
        )}
      </div>
      <div className={styles.mobileOnly}>
        <MenuButton icon={<LogoutIcon size={15} />} label={t('logout')} to={Routes.LOGOUT} />
      </div>

      <div className={c(styles.menuContainer, collapsed && styles.collapsedMenuContainer)}>
        <div className={styles.menu}>
          <NavLink
            to={hasPermissionToViewProfile ? VIEW_OPERATOR.replace(':id', authUser.userId) : ''}
            className={c(styles.menuLink, !hasPermissionToViewProfile && styles.linkDisabled)}
          >
            <div className={styles.menuHeader}>
              <div className={styles.menuProfile}>
                {authUser.name && <span title={authUser.name}>{getInitials(authUser.name)}</span>}
              </div>
              <div className={styles.menuUserInformation}>
                <span title={authUser.name} className={styles.menuInformation}>
                  {authUser.name}
                </span>
                <span title={authUser.email} className={styles.menuInformation}>
                  {authUser.email}
                </span>
              </div>
            </div>
          </NavLink>
          <MenuButton icon={<LogoutIcon size={15} />} label={t('logout')} to={Routes.LOGOUT} />
        </div>
      </div>
    </div>
  )
}

ProfileActions.propTypes = {
  collapsed: PropTypes.bool.isRequired,
}

export default ProfileActions
