import i18next from 'i18next'

// PLANNING STATUSES
export const PENDING_STATUS = 'pending'
export const ASSIGNED_STATUS = 'assigned'
export const FINISHED_STATUS = 'finished'
export const PLANNED_STATUS = 'planned'

class PlanningStatus {
  constructor(status) {
    this.status = status
  }

  static statusOptions() {
    return [
      {
        id: PENDING_STATUS,
        value: i18next.t(`plannings:${PENDING_STATUS}`),
      },
      {
        id: PLANNED_STATUS,
        value: i18next.t(`plannings:${PLANNED_STATUS}`),
      },
      {
        id: ASSIGNED_STATUS,
        value: i18next.t(`plannings:${ASSIGNED_STATUS}`),
      },
      {
        id: FINISHED_STATUS,
        value: i18next.t(`plannings:${FINISHED_STATUS}`),
      },
    ]
  }

  get isPending() {
    return this.status === PENDING_STATUS
  }

  get isPlanned() {
    return this.status === PLANNED_STATUS
  }

  get isAssigned() {
    return this.status === ASSIGNED_STATUS
  }

  get isFinished() {
    return this.status === FINISHED_STATUS
  }

  static fromJson(status) {
    return new PlanningStatus(status)
  }
}

export default PlanningStatus
