import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import styles from './tablePagination.module.scss'

const Page = ({ page, pageLabel, handleClickPage, isLoading, active }) => {
  return (
    <li
      className={c(styles.page, isLoading && styles.loadingPage, active && styles.active)}
      onClick={() => handleClickPage(page)}
      role="menuitem"
    >
      {isLoading ? null : pageLabel}
    </li>
  )
}

Page.defaultProps = {
  pageLabel: null,
  isLoading: false,
  active: false,
}

Page.propTypes = {
  handleClickPage: PropTypes.func.isRequired,
  page: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  active: PropTypes.bool,
  isLoading: PropTypes.bool,
  pageLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.any]),
}

export default Page
