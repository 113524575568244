import c from 'classnames'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import Input from 'subFramework/presentation/Input'
import InputStore from 'subFramework/stores/InputStore'
import styles from './inputWrapper.module.scss'

const InputWrapper = ({
  wrapperClassName,
  translationFile,
  as: Component,
  placeholder,
  description,
  inputStore,
  alignError,
  inputRef,
  fullHeight,
  required,
  literal,
  label,
  value,
  name,
  autoSize,
  eye,
  ...props
}) => {
  const { t } = useTranslation(translationFile)
  let useLabel = label
  let usePlaceholder = placeholder

  if (name !== null) {
    if (label === null) {
      useLabel = t(`${name}Label`)
    }

    if (placeholder === null) {
      usePlaceholder = t(`${name}Placeholder`)
    }
  }

  if (required && !literal) {
    useLabel = `${useLabel} *`
  }

  if (Array.isArray(props.options) && props.options.length > 0) {
    // eslint-disable-next-line
    props.options = props.options.slice().sort((a, b) => a.value.localeCompare(b.value))
  }

  if (Array.isArray(props.defaultOptions) && props.defaultOptions.length > 0) {
    // eslint-disable-next-line
    props.defaultOptions = props.defaultOptions
      .slice()
      .sort((a, b) => a.value.localeCompare(b.value))
  }

  return (
    <div
      className={c(
        styles.wrapper,
        fullHeight && styles.wrapperFullHeight,
        wrapperClassName && wrapperClassName
      )}
    >
      <Component
        value={(inputStore && inputStore.value) || value}
        error={inputStore && inputStore.error}
        placeholder={usePlaceholder}
        inputRef={inputRef}
        literal={literal}
        label={useLabel}
        eye={eye}
        {...props}
      />
      {inputStore && inputStore.error && inputStore.errorMessage !== '' && (
        <small
          className={c(styles.error, literal && styles.errorLiteral, styles[`align-${alignError}`])}
        >
          {t(inputStore.errorMessage)}
        </small>
      )}
      {description && <span className={styles.description}>{description}</span>}
    </div>
  )
}

InputWrapper.propTypes = {
  alignError: PropTypes.oneOf(['start', 'center', 'end']),
  value: PropTypes.oneOfType([PropTypes.any]),
  as: PropTypes.oneOfType([PropTypes.any]),
  inputStore: PropTypes.instanceOf(InputStore),
  wrapperClassName: PropTypes.string,
  translationFile: PropTypes.string,
  placeholder: PropTypes.string,
  description: PropTypes.string,
  inputRef: PropTypes.func,
  required: PropTypes.bool,
  fullHeight: PropTypes.bool,
  label: PropTypes.string,
  literal: PropTypes.bool,
  name: PropTypes.string,
  eye: PropTypes.string,
  autoSize: PropTypes.bool,
  options: PropTypes.oneOfType([PropTypes.any]),
  defaultOptions: PropTypes.oneOfType([PropTypes.any]),
}

InputWrapper.defaultProps = {
  wrapperClassName: null,
  translationFile: '',
  eye: '',
  alignError: 'start',
  fullHeight: false,
  placeholder: null,
  description: null,
  inputStore: null,
  required: false,
  literal: false,
  inputRef: null,
  label: null,
  name: null,
  as: Input,
  value: '',
  autoSize: true,
  options: null,
  defaultOptions: null,
}

export default memo(observer(InputWrapper))
