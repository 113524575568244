import React from 'react'
import c from 'classnames'
import PropTypes from 'prop-types'
import styles from './executorIcon.module.scss'

const ExecutorIcon = ({ className, size }) => {
  return (
    <svg
      width={size}
      className={c(styles.icon, className)}
      viewBox="0 0 19 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.938 18.75a.9.9 0 0 1-.657-.281.9.9 0 0 1-.281-.657V5.938a.9.9 0 0 1 .281-.656A.9.9 0 0 1 5.938 5h6.875a.9.9 0 0 1 .656.281.9.9 0 0 1 .281.657v11.875a.9.9 0 0 1-.281.656.9.9 0 0 1-.656.281H5.936zm0-1.406v.468h6.875v-.468H5.936zm0-.938h6.875V7.344H5.936v9.062zm0-10h6.875v-.468H5.936v.468z"
        fill="#fff"
      />
    </svg>
  )
}

ExecutorIcon.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number,
}

ExecutorIcon.defaultProps = {
  className: null,
  size: 20,
}

export default ExecutorIcon
