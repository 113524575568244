import c from 'classnames'
import PropTypes from 'prop-types'
import styles from './plannerIcon.module.scss'

const PlannerIcon = ({ className, size }) => {
  return (
    <svg
      className={c(styles.icon, className)}
      width={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 9.375H13.125C12.462 9.375 11.8261 9.11161 11.3572 8.64277C10.8884 8.17393 10.625 7.53804 10.625 6.875V5C10.625 4.33696 10.8884 3.70107 11.3572 3.23223C11.8261 2.76339 12.462 2.5 13.125 2.5H15C15.663 2.5 16.2989 2.76339 16.7678 3.23223C17.2366 3.70107 17.5 4.33696 17.5 5V6.875C17.5 7.53804 17.2366 8.17393 16.7678 8.64277C16.2989 9.11161 15.663 9.375 15 9.375ZM13.125 3.75C12.7935 3.75 12.4755 3.8817 12.2411 4.11612C12.0067 4.35054 11.875 4.66848 11.875 5V6.875C11.875 7.20652 12.0067 7.52446 12.2411 7.75888C12.4755 7.9933 12.7935 8.125 13.125 8.125H15C15.3315 8.125 15.6495 7.9933 15.8839 7.75888C16.1183 7.52446 16.25 7.20652 16.25 6.875V5C16.25 4.66848 16.1183 4.35054 15.8839 4.11612C15.6495 3.8817 15.3315 3.75 15 3.75H13.125Z"
        fill="#0570EE"
      />
      <path
        d="M6.875 9.375H5C4.33696 9.375 3.70107 9.11161 3.23223 8.64277C2.76339 8.17393 2.5 7.53804 2.5 6.875V5C2.5 4.33696 2.76339 3.70107 3.23223 3.23223C3.70107 2.76339 4.33696 2.5 5 2.5H6.875C7.53804 2.5 8.17393 2.76339 8.64277 3.23223C9.11161 3.70107 9.375 4.33696 9.375 5V6.875C9.375 7.53804 9.11161 8.17393 8.64277 8.64277C8.17393 9.11161 7.53804 9.375 6.875 9.375ZM5 3.75C4.66848 3.75 4.35054 3.8817 4.11612 4.11612C3.8817 4.35054 3.75 4.66848 3.75 5V6.875C3.75 7.20652 3.8817 7.52446 4.11612 7.75888C4.35054 7.9933 4.66848 8.125 5 8.125H6.875C7.20652 8.125 7.52446 7.9933 7.75888 7.75888C7.9933 7.52446 8.125 7.20652 8.125 6.875V5C8.125 4.66848 7.9933 4.35054 7.75888 4.11612C7.52446 3.8817 7.20652 3.75 6.875 3.75H5Z"
        fill="#0570EE"
      />
      <path
        d="M6.875 17.5H5C4.33696 17.5 3.70107 17.2366 3.23223 16.7678C2.76339 16.2989 2.5 15.663 2.5 15V13.125C2.5 12.462 2.76339 11.8261 3.23223 11.3572C3.70107 10.8884 4.33696 10.625 5 10.625H6.875C7.53804 10.625 8.17393 10.8884 8.64277 11.3572C9.11161 11.8261 9.375 12.462 9.375 13.125V15C9.375 15.663 9.11161 16.2989 8.64277 16.7678C8.17393 17.2366 7.53804 17.5 6.875 17.5ZM5 11.875C4.66848 11.875 4.35054 12.0067 4.11612 12.2411C3.8817 12.4755 3.75 12.7935 3.75 13.125V15C3.75 15.3315 3.8817 15.6495 4.11612 15.8839C4.35054 16.1183 4.66848 16.25 5 16.25H6.875C7.20652 16.25 7.52446 16.1183 7.75888 15.8839C7.9933 15.6495 8.125 15.3315 8.125 15V13.125C8.125 12.7935 7.9933 12.4755 7.75888 12.2411C7.52446 12.0067 7.20652 11.875 6.875 11.875H5Z"
        fill="#0570EE"
      />
      <path
        d="M15 17.5H13.125C12.462 17.5 11.8261 17.2366 11.3572 16.7678C10.8884 16.2989 10.625 15.663 10.625 15V13.125C10.625 12.462 10.8884 11.8261 11.3572 11.3572C11.8261 10.8884 12.462 10.625 13.125 10.625H15C15.663 10.625 16.2989 10.8884 16.7678 11.3572C17.2366 11.8261 17.5 12.462 17.5 13.125V15C17.5 15.663 17.2366 16.2989 16.7678 16.7678C16.2989 17.2366 15.663 17.5 15 17.5ZM13.125 11.875C12.7935 11.875 12.4755 12.0067 12.2411 12.2411C12.0067 12.4755 11.875 12.7935 11.875 13.125V15C11.875 15.3315 12.0067 15.6495 12.2411 15.8839C12.4755 16.1183 12.7935 16.25 13.125 16.25H15C15.3315 16.25 15.6495 16.1183 15.8839 15.8839C16.1183 15.6495 16.25 15.3315 16.25 15V13.125C16.25 12.7935 16.1183 12.4755 15.8839 12.2411C15.6495 12.0067 15.3315 11.875 15 11.875H13.125Z"
        fill="#0570EE"
      />
    </svg>
  )
}

PlannerIcon.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number,
}

PlannerIcon.defaultProps = {
  className: null,
  size: 20,
}

export default PlannerIcon
