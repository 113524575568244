import React from 'react'
import c from 'classnames'
import PropTypes from 'prop-types'
import styles from './truckIcon.module.scss'

const TruckIcon = ({ className, size }) => {
  return (
    <svg
      className={c(styles.icon, className)}
      width={size}
      viewBox="0 0 900 900"
      preserveAspectRatio="xMidYMid meet"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 16V400H67.3c10.4-36.9 44.4-64 84.7-64s74.2 27.1 84.7 64H400V16H16zM64.4 416H16 0V400 16 0H16 400h16V16 96h72 3.3l2.3 2.3 112 112 2.3 2.3V216 400h24 8v16h-8H575.6c.2 2.6 .4 5.3 .4 8c0 48.6-39.4 88-88 88s-88-39.4-88-88c0-2.7 .1-5.4 .4-8H400 239.6c.2 2.6 .4 5.3 .4 8c0 48.6-39.4 88-88 88s-88-39.4-88-88c0-2.7 .1-5.4 .4-8zM416 216H588.7l-104-104H416V216zm0 16V373.4c15.9-22.6 42.2-37.4 72-37.4c40.3 0 74.2 27.1 84.7 64H592V232H416zM224 424A72 72 0 1 0 80 424a72 72 0 1 0 144 0zm264 72a72 72 0 1 0 0-144 72 72 0 1 0 0 144z"
        fill="#454545"
        transform="translate(150, 200)"
      />
    </svg>
  )
}

TruckIcon.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number,
}

TruckIcon.defaultProps = {
  className: null,
  size: 20,
}

export default TruckIcon
