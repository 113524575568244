import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { useContext, useEffect, useState } from 'react'
import { LoadingRing, StoreContext } from 'subFramework'

const AuthPreLoaders = ({ children }) => {
  const [isReady, setIsReady] = useState(false)
  const { settingsStore } = useContext(StoreContext)

  useEffect(() => {
    settingsStore.getSettings().then(() => {
      setIsReady(true)
    })
  }, [])

  if (!isReady) {
    return <LoadingRing center />
  }

  return children
}

AuthPreLoaders.propTypes = {
  children: PropTypes.node.isRequired,
}

export default observer(AuthPreLoaders)
