import PropTypes from 'prop-types'
import { Navigate } from 'react-router-dom'
import { useLocation } from 'react-router'
import { LOGOUT } from 'routing/paths'

const PrivateRoute = ({ authStore, children, isAuthenticated, redirectPath }) => {
  const location = useLocation()

  if (!isAuthenticated) {
    if (location.pathname !== LOGOUT) {
      authStore.setLoginRedirectRoute(location.pathname + location.search)
    }

    return (
      <Navigate
        to={{
          pathname: redirectPath,
        }}
      />
    )
  }

  return children
}

PrivateRoute.propTypes = {
  redirectPath: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  authStore: PropTypes.oneOfType([PropTypes.object]).isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
}

export default PrivateRoute
