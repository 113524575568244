import React from 'react'
import c from 'classnames'
import PropTypes from 'prop-types'
import styles from './arrowIcon.module.scss'

const ArrowIcon = ({ type, className, size, ...props }) => {
  return (
    <svg
      className={c(styles.icon, styles[type], className)}
      width={size}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.186 13.643a.628.628 0 0 0 .9 0l5.728-5.185a.657.657 0 0 0 0-.917L6.086 2.356a.628.628 0 0 0-.9 0 .657.657 0 0 0 0 .917L10.464 8l-5.278 4.726a.657.657 0 0 0 0 .917z"
        fill="#0570EE"
      />
    </svg>
  )
}

ArrowIcon.propTypes = {
  type: PropTypes.oneOf(['up', 'down', 'left', 'right']),
  className: PropTypes.string,
  size: PropTypes.number,
}

ArrowIcon.defaultProps = {
  type: 'right',
  className: null,
  size: 20,
}

export default ArrowIcon
