import React from 'react'
import c from 'classnames'
import PropTypes from 'prop-types'
import styles from './mapIconPin.module.scss'

const MapIconPin = ({ className, size }) => {
  return (
    <svg
      className={c(styles.icon, className)}
      width={size}
      viewBox="0 0 900 900"
      preserveAspectRatio="xMidYMid meet"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M375 173.5c10.9-21.4 17-39.9 17-53.5c0-57.4-46.6-104-104-104s-104 46.6-104 104c0 13.6 6.1 32.1 17 53.5c10.7 21 25.2 43.1 39.9 63.3c14.7 20.2 29.4 38.3 40.5 51.4c2.4 2.8 4.6 5.3 6.5 7.6c2-2.3 4.2-4.8 6.5-7.6c11.1-13.1 25.8-31.2 40.5-51.4c14.7-20.3 29.2-42.3 39.9-63.3zM298.7 308c-6.6 7.6-10.7 12-10.7 12s-4.1-4.4-10.7-12C247.9 274.2 168 177.1 168 120C168 53.7 221.7 0 288 0s120 53.7 120 120c0 57.1-79.9 154.2-109.3 188zM560 150.5L414.8 201c1-1.9 2-3.8 3-5.7c2.4-4.8 4.8-9.7 7.1-14.7l135.2-47 16-5.6v16.9V448L392 512 184 448 16 506.4 0 512V495.1 192l138.7-48.2c1.1 5.2 2.5 10.3 4.2 15.5L16 203.4V489.5l160-55.7V288h16V433.7l192 59.1V288h16V492.3l160-55.7V150.5zM320 112a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm-80 0a48 48 0 1 1 96 0 48 48 0 1 1 -96 0z"
        fill="#454545"
        transform="translate(150, 200)"
      />
    </svg>
  )
}

MapIconPin.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number,
}

MapIconPin.defaultProps = {
  className: null,
  size: 20,
}

export default MapIconPin
