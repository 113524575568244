import { WORKFLOWS_API_URL } from 'services/config'
import axios from 'axios'
import Tenant from 'models/Tenant'

class RootService {
  tenantData = () => {
    return axios.get(`${WORKFLOWS_API_URL}/tenants`).then((response) => {
      return Tenant.fromJson(response.data)
    })
  }
}

export default RootService
