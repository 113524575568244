import compactLogo from 'assets/compact-logo.svg'
import AccountingIcon from 'assets/icons/AccountingIcon'
import AnalitycsIcon from 'assets/icons/AnalitycsIcon'
import AssignmentOrdersIcon from 'assets/icons/AssignmentOrdersIcon'
import ExecutorIcon from 'assets/icons/ExecutorIcon'
import FilterIcon from 'assets/icons/FilterIcon'
import MapIcon from 'assets/icons/MapIcon'
import MapIconPin from 'assets/icons/MapIconPin'
import OfficeIcon from 'assets/icons/OfficeIcon'
import OperatorIcon from 'assets/icons/OperatorIcon'
import PlannerIcon from 'assets/icons/PlannerIcon'
import PlanningOrdersIcon from 'assets/icons/PlanningOrdersIcon'
import RealTimeWorkOrderIcon from 'assets/icons/RealTimeWorkOrderIcon'
import RolesIcon from 'assets/icons/RolesIcon'
import RoutingOrdersIcon from 'assets/icons/RoutingOrdersIcon'
import TaskListIcon from 'assets/icons/TaskListIcon'
import TruckIcon from 'assets/icons/TruckIcon'
import WorkOrderIcon from 'assets/icons/WorkOrderIcon'
import desktopLogo from 'assets/secondaryLogo.svg'
import c from 'classnames'
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon'
import ArrowLeftBoldCircleIcon from 'mdi-react/ArrowLeftBoldCircleIcon'
import ArrowRightBoldCircleIcon from 'mdi-react/ArrowRightBoldCircleIcon'
import CloseIcon from 'mdi-react/CloseIcon'
import MenuButton from 'presentation/MenuButton'
import ProfileActions from 'presentation/ProfileActions'
import PropTypes from 'prop-types'
import { memo, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import * as Routes from 'routing/paths'
import * as permissions from 'routing/permissions'
import AuthService from 'services/AuthService'
import { StoreContext } from 'subFramework'
import styles from './authorizedNavBar.module.scss'

const AuthorizedNavBar = ({ handleClickMenu, collapsed, handleClickCollapse }) => {
  const { t } = useTranslation()
  const { authStore } = useContext(StoreContext)
  const authService = new AuthService()

  const [authPowerBi, setAuthPowerBi] = useState(null)

  const [isSubMenuOpen, setShowSubMenu] = useState(false)
  // const [showSubMenuOffice, setShowSubMenuOffice] = useState(false)

  const fetchAuthData = async () => {
    const data = await authService.getTenantFeature('powerbi-data-analytics')
    setAuthPowerBi(data)
  }

  useEffect(() => {
    fetchAuthData()
  }, [])

  const handleSubMenuToggle = () => {
    // setShowSubMenuOffice(false)
    setShowSubMenu(!isSubMenuOpen)
  }

  // const handleSubMenuToggleOffice = () => {
  //   // setShowSubMenu(false)
  //   setShowSubMenuOffice(!showSubMenuOffice)
  // }

  const closeSubMenu = () => {
    setShowSubMenu(false)
    //   setShowSubMenuOffice(false)
  }

  const subMenuDesplegable = [
    { label: 'Vehículos', to: Routes.VEHICLES },
    { label: 'Proveedores', to: Routes.SUPPLIERS },
    { label: 'Tipos de carga', to: Routes.LOAD_TYPES },
    { label: 'Tipos de vehículo', to: Routes.VEHICLES_TYPES },
  ]

  // const subMenuAcceso = [
  //   { label: 'Acceso', to: Routes.ACCESS_LIST },
  //   { label: 'Oficinas', to: Routes.OFFICES_LIST },
  // ]

  return (
    <div className={c(styles.authorizedNavBarContainer, collapsed && styles.collapsed)}>
      <span>
        <CloseIcon size={23} onClick={handleClickMenu} className={styles.closeIcon} />
      </span>
      <div className={styles.headerIconsContainer}>
        <NavLink to={authStore.dashboardRoute} title="WitWot">
          {collapsed ? (
            <img src={compactLogo} className={styles.compactLogo} alt="WitWot" />
          ) : (
            <img src={desktopLogo} className={styles.desktopLogo} alt="WitWot" />
          )}
        </NavLink>
        <span>
          {collapsed ? (
            <ArrowRightBoldCircleIcon
              onClick={handleClickCollapse}
              size={22}
              className={c(styles.actionIcon, styles.expandIcon)}
            />
          ) : (
            <ArrowLeftBoldCircleIcon
              onClick={handleClickCollapse}
              size={22}
              className={c(styles.actionIcon, styles.collapseIcon)}
            />
          )}
        </span>
      </div>
      {!collapsed && authStore?.authUser?.tenant?.label && (
        <div className={styles.tenantLabel}>{authStore.authUser.tenant.label}</div>
      )}
      {(authStore.can(permissions.MENU_PLANNER) ||
        authStore.can(permissions.MENU_PLANNINGS) ||
        authStore.can(permissions.MENU_ROUTER) ||
        authStore.can(permissions.MENU_ASSIGNER) ||
        authStore.can(permissions.ZONES_LIST) ||
        authStore.can(permissions.GLOBAL_PARAMETERS) ||
        authStore.can(permissions.LIST_VEHICLES)) && (
        <div className={styles.navSection}>
          <div className={styles.navigationTitle}>{t('operativeDesign')}</div>
          <MenuButton
            permission={permissions.MENU_PLANNER}
            icon={<PlannerIcon size={17} className={styles.plannerIcon} />}
            to={Routes.PLANNER}
            label={t('planner')}
            collapsed={collapsed}
            closeSubMenu={closeSubMenu}
          />
          <MenuButton
            permission={permissions.MENU_ASSIGNER}
            icon={<AssignmentOrdersIcon size={16} className={styles.assignerIcon} />}
            to={Routes.ASSIGNER}
            label={t('assigner')}
            collapsed={collapsed}
            closeSubMenu={closeSubMenu}
          />
          <MenuButton
            permission={permissions.MENU_ROUTER}
            icon={<RoutingOrdersIcon size={13} className={styles.routerIcon} />}
            to={Routes.ROUTER}
            label={t('router')}
            collapsed={collapsed}
            closeSubMenu={closeSubMenu}
          />
          <MenuButton
            permission={permissions.MENU_PLANNINGS}
            icon={<PlanningOrdersIcon size={14} className={styles.planningIcon} />}
            to={Routes.PLANNINGS_LIST}
            label={t('historical')}
            collapsed={collapsed}
            closeSubMenu={closeSubMenu}
          />
        </div>
      )}
      <div className={styles.navSection}>
        <div className={styles.navigationTitle}>{t('accessControl')}</div>
        {authStore.can(permissions.OFFICES_LIST) && (
          <>
            <MenuButton
              permission={permissions.OFFICES_LIST}
              icon={<OfficeIcon size={22} />}
              label={t('access')}
              collapsed={collapsed}
              to={Routes.ACCESS_LIST}
              closeSubMenu={closeSubMenu}
            />
            <MenuButton
              permission={permissions.OFFICES_LIST}
              icon={<OfficeIcon size={22} />}
              label={t('offices')}
              collapsed={collapsed}
              to={Routes.OFFICES_LIST}
              closeSubMenu={closeSubMenu}
            />
          </>
        )}
      </div>

      {(authStore.can(permissions.WORK_ORDERS_LIST) ||
        authStore.can(permissions.TASKS_LIST) ||
        authStore.can(permissions.TASKS_ACCOUNTING_LIST)) && (
        <div className={styles.navSection}>
          <div className={styles.navigationTitle}>{t('operations')}</div>
          <MenuButton
            permission={permissions.TASKS_LIST}
            icon={<TaskListIcon size={22} />}
            label={t('historicalTasks')}
            to={Routes.TASKS_LIST}
            collapsed={collapsed}
            closeSubMenu={closeSubMenu}
          />
          <MenuButton
            permission={permissions.WORK_ORDERS_LIST}
            icon={<WorkOrderIcon size={23} />}
            to={Routes.WORK_ORDERS_LIST}
            label={t('workOrders')}
            collapsed={collapsed}
            closeSubMenu={closeSubMenu}
          />
          <MenuButton
            permission={permissions.TASKS_ACCOUNTING_LIST}
            icon={<AccountingIcon size={22} />}
            label={t('tasksAccounting')}
            to={Routes.TASKS_ACCOUNTING_LIST}
            collapsed={collapsed}
            closeSubMenu={closeSubMenu}
          />
        </div>
      )}
      {(authStore.can(permissions.VIEW_MAP) ||
        authStore.can(permissions.VIEW_LIVE_WORK_ORDERS) ||
        authStore.can(permissions.VIEW_TASK)) && (
        <div className={styles.navSection}>
          <div className={styles.navigationTitle}>{t('realTime')}</div>

          <MenuButton
            permission={permissions.VIEW_MAP}
            icon={<MapIcon size={24} />}
            to={Routes.DASHBOARD}
            collapsed={collapsed}
            closeSubMenu={closeSubMenu}
            label={t('trackingworkOrders')}
          />
          <MenuButton
            permission={permissions.VIEW_MAP}
            icon={<TruckIcon size={24} />}
            to={Routes.USERSTRACKING}
            collapsed={collapsed}
            closeSubMenu={closeSubMenu}
            label={t('usersTracking')}
          />
          <MenuButton
            permission={permissions.VIEW_LIVE_WORK_ORDERS}
            icon={<RealTimeWorkOrderIcon size={22} />}
            label={t('liveWorkOrders')}
            to={Routes.LIVE_WORK_ORDERS}
            collapsed={collapsed}
            closeSubMenu={closeSubMenu}
          />
        </div>
      )}
      {authPowerBi &&
        (authStore.can(permissions.ANALYTICS_VIEW_TASKS) ||
          authStore.can(permissions.ANALYTICS_VIEW_ACCOUNTING)) && (
          <div className={styles.navSection}>
            <div className={styles.navigationTitle}>{t('analytics')}</div>
            <div className={styles.menuButtonWrapper}>
              {authStore.can(permissions.ANALYTICS_VIEW_TASKS) && (
                <MenuButton
                  permission="yes"
                  icon={<AnalitycsIcon size={22} />}
                  label={t('analyticsTasks')}
                  collapsed={collapsed}
                  closeSubMenu={closeSubMenu}
                  to={Routes.ANALYTICS_TASKS}
                />
              )}
              {authStore.can(permissions.ANALYTICS_VIEW_ACCOUNTING) && (
                <MenuButton
                  permission="yes"
                  icon={<AnalitycsIcon size={22} />}
                  label={t('analyticsAccounting')}
                  collapsed={collapsed}
                  closeSubMenu={closeSubMenu}
                  to={Routes.ANALYTICS_ACCOUNTING}
                />
              )}
            </div>
          </div>
        )}
      {(authStore.can(permissions.LIST_CLIENTS) ||
        authStore.can(permissions.LIST_EXECUTORS) ||
        authStore.can(permissions.LIST_OPERATORS) ||
        authStore.can(permissions.LIST_ROLES) ||
        authStore.can(permissions.ZONES_LIST) ||
        authStore.can(permissions.GLOBAL_PARAMETERS) ||
        authStore.can(permissions.LIST_VEHICLES)) && (
        <div className={styles.navSection}>
          <div className={styles.navigationTitle}>{t('configuration')}</div>
          {(authStore.can(permissions.ZONES_LIST) ||
            authStore.can(permissions.GLOBAL_PARAMETERS) ||
            authStore.can(permissions.LIST_VEHICLES)) &&
          !(
            authStore.can(permissions.LIST_CLIENTS) ||
            authStore.can(permissions.LIST_EXECUTORS) ||
            authStore.can(permissions.LIST_OPERATORS) ||
            authStore.can(permissions.LIST_ROLES)
          ) ? (
            <div className={styles.menuButtonsContainer}>
              {authStore.can(permissions.GLOBAL_PARAMETERS) && (
                <MenuButton
                  permission={permissions.GLOBAL_PARAMETERS}
                  icon={<FilterIcon size={22} />}
                  to={Routes.GLOBAL_PARAMETERS}
                  label={t('globalParameters')}
                  collapsed={collapsed}
                  closeSubMenu={closeSubMenu}
                />
              )}
              {authStore.can(permissions.ZONES_LIST) && (
                <MenuButton
                  permission={permissions.ZONES_LIST}
                  icon={<MapIconPin size={22} />}
                  to={Routes.ZONES}
                  label={t('zones')}
                  collapsed={collapsed}
                  closeSubMenu={closeSubMenu}
                />
              )}
            </div>
          ) : (
            <div className={styles.navSection}>
              {authStore.can(permissions.GLOBAL_PARAMETERS) && (
                <MenuButton
                  permission={permissions.GLOBAL_PARAMETERS}
                  icon={<FilterIcon size={22} />}
                  to={Routes.GLOBAL_PARAMETERS}
                  label={t('globalParameters')}
                  collapsed={collapsed}
                  closeSubMenu={closeSubMenu}
                />
              )}
              {authStore.can(permissions.ZONES_LIST) && (
                <MenuButton
                  permission={permissions.ZONES_LIST}
                  icon={<MapIconPin size={22} />}
                  to={Routes.ZONES}
                  label={t('zones')}
                  collapsed={collapsed}
                  closeSubMenu={closeSubMenu}
                />
              )}
              {/* {authStore.can(permissions.LIST_VEHICLES) && (
                <MenuButton
                  permission={permissions.LIST_VEHICLES}
                  icon={<TruckIcon size={22} />}
                  to={Routes.VEHICLES_LIST}
                  label={t('vehicles')}
                  collapsed={collapsed}
                  closeSubMenu={closeSubMenu}
                />
              )} */}
              <div className={styles.menuButtonWrapper}>
                <MenuButton
                  permission="yes"
                  icon={<TruckIcon size={22} />}
                  label={t('fleetManagement')}
                  collapsed={collapsed}
                  dropdownItems={subMenuDesplegable}
                  isSubMenuOpen={isSubMenuOpen}
                  handleSubMenuToggle={handleSubMenuToggle}
                />
              </div>
              {authStore.can(permissions.LIST_CLIENTS) && (
                <MenuButton
                  permission={permissions.LIST_CLIENTS}
                  icon={<AccountOutlineIcon size={22} />}
                  to={Routes.CLIENTS_LIST}
                  label={t('clients')}
                  collapsed={collapsed}
                  closeSubMenu={closeSubMenu}
                />
              )}

              {authStore.can(permissions.LIST_EXECUTORS) && (
                <MenuButton
                  permission={permissions.LIST_EXECUTORS}
                  icon={<ExecutorIcon size={22} />}
                  to={Routes.EXECUTORS_LIST}
                  label={t('executors')}
                  collapsed={collapsed}
                  closeSubMenu={closeSubMenu}
                />
              )}
              {authStore.can(permissions.LIST_OPERATORS) && (
                <MenuButton
                  permission={permissions.LIST_OPERATORS}
                  icon={<OperatorIcon size={22} />}
                  to={Routes.OPERATORS_LIST}
                  label={t('operators')}
                  collapsed={collapsed}
                  closeSubMenu={closeSubMenu}
                />
              )}
              {authStore.can(permissions.LIST_ROLES) && (
                <MenuButton
                  permission={permissions.LIST_ROLES}
                  icon={<RolesIcon size={22} />}
                  label={t('roles')}
                  to={Routes.ROLES_LIST}
                  collapsed={collapsed}
                  closeSubMenu={closeSubMenu}
                />
              )}
            </div>
          )}
        </div>
      )}

      <div className={styles.bottomSection}>
        <ProfileActions collapsed={collapsed} />
      </div>
      <span className={styles.versionInformation}>V2.00.00</span>
    </div>
  )
}

AuthorizedNavBar.defaultProps = {
  collapsed: false,
  handleClickCollapse: () => null,
}

AuthorizedNavBar.propTypes = {
  handleClickMenu: PropTypes.func.isRequired,
  handleClickCollapse: PropTypes.func,
  collapsed: PropTypes.bool,
}

export default memo(AuthorizedNavBar)
