import React from 'react'
import c from 'classnames'
import PropTypes from 'prop-types'
import styles from './assignmentOrdersIcon.module.scss'

const AssignmentOrdersIcon = ({ className, size }) => {
  return (
    <svg
      className={c(styles.icon, className)}
      width={size}
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.475 4.61875L8.66875 3.8125C8.59375 3.7375 8.50625 3.70313 8.40625 3.70938C8.30625 3.71563 8.21875 3.75625 8.14375 3.83125C8.06875 3.90625 8.03125 3.99375 8.03125 4.09375C8.03125 4.19375 8.06875 4.28125 8.14375 4.35625L9.08125 5.29375C9.19375 5.40625 9.325 5.4625 9.475 5.4625C9.625 5.4625 9.75625 5.40625 9.86875 5.29375L11.8563 3.2875C11.9313 3.2125 11.9688 3.125 11.9688 3.025C11.9688 2.925 11.9313 2.8375 11.8563 2.7625C11.7812 2.6875 11.6938 2.65 11.5938 2.65C11.4938 2.65 11.4062 2.6875 11.3313 2.7625L9.475 4.61875ZM0.8125 13C0.653125 13 0.519531 12.9458 0.411719 12.8373C0.303906 12.7289 0.25 12.5945 0.25 12.4342C0.25 12.2739 0.303906 12.1406 0.411719 12.0344C0.519531 11.9281 0.653125 11.875 0.8125 11.875H8.6875C8.84688 11.875 8.98047 11.9292 9.08828 12.0377C9.19609 12.1461 9.25 12.2805 9.25 12.4408C9.25 12.6011 9.19609 12.7344 9.08828 12.8406C8.98047 12.9469 8.84688 13 8.6875 13H0.8125ZM9.99801 7.5625C9.01184 7.5625 8.17188 7.21496 7.47813 6.51989C6.78438 5.82481 6.4375 4.98419 6.4375 3.99801C6.4375 3.01184 6.78504 2.17188 7.48011 1.47813C8.17519 0.784375 9.01581 0.4375 10.002 0.4375C10.9882 0.4375 11.8281 0.785038 12.5219 1.48011C13.2156 2.17519 13.5625 3.01581 13.5625 4.00199C13.5625 4.98816 13.215 5.82812 12.5199 6.52188C11.8248 7.21563 10.9842 7.5625 9.99801 7.5625ZM0.8125 6.625C0.653125 6.625 0.519531 6.57078 0.411719 6.46234C0.303906 6.35389 0.25 6.21952 0.25 6.05922C0.25 5.89891 0.303906 5.76562 0.411719 5.65938C0.519531 5.55313 0.653125 5.5 0.8125 5.5H5.1625C5.229 5.70404 5.305 5.89879 5.3905 6.08427C5.476 6.26976 5.56875 6.45 5.66875 6.625H0.8125ZM0.8125 9.8125C0.653125 9.8125 0.519531 9.75828 0.411719 9.64984C0.303906 9.54139 0.25 9.40702 0.25 9.24672C0.25 9.08641 0.303906 8.95312 0.411719 8.84688C0.519531 8.74063 0.653125 8.6875 0.8125 8.6875H8.10625C8.27875 8.76705 8.4625 8.83381 8.6575 8.88779C8.8525 8.94176 9.05 8.98125 9.25 9.00625V9.25C9.25 9.40938 9.19609 9.54297 9.08828 9.65078C8.98047 9.75859 8.84688 9.8125 8.6875 9.8125H0.8125Z"
        fill="#0570EE"
      />
    </svg>
  )
}

AssignmentOrdersIcon.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number,
}

AssignmentOrdersIcon.defaultProps = {
  className: null,
  size: 20,
}

export default AssignmentOrdersIcon
