import axios from 'axios'
import { WORKFLOWS_API_URL } from 'services/config'
import Settings from 'models/Settings'

class SettingsService {
  getSettings = () => {
    return axios.get(`${WORKFLOWS_API_URL}/configs`).then(({ data }) => Settings.fromJson(data))
  }

  saveSettings = (data) => {
    return axios
      .post(`${WORKFLOWS_API_URL}/configs`, data)
      .then((response) => Settings.fromJson(response.data))
  }
}

export default SettingsService
