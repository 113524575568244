import c from 'classnames'
import PropTypes from 'prop-types'
import styles from './officeIcon.module.scss'

const OfficeIcon = ({ className, size, heightIcon }) => {
  return (
    <svg
      width={size}
      viewBox="0 0 42 35"
      className={c(styles.icon, className)}
      height={heightIcon}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.85 10.8645H34.6577V8.05675H31.85V10.8645ZM31.85 19.2606H34.6577V16.4529H31.85V19.2606ZM31.85 27.6259H34.6577V24.8183H31.85V27.6259ZM28.5692 34.9375V32.6683H39.7307V3.20675H19.8461V8.8452L17.5769 7.2645V0.93755H41.9999V34.9375H28.5692ZM0 34.9375V16.8029L12.6654 7.7568L25.2999 16.7965V34.9375H15.4115V25.4067H9.9192V34.9375H0ZM2.2692 32.6683H7.65V23.1375H17.6807V32.6683H23.0307V17.9683L12.6654 10.5913L2.2692 17.9946V32.6683Z"
        fill="#454545"
      />
    </svg>
  )
}

OfficeIcon.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number,
  heightIcon: PropTypes.number,
}

OfficeIcon.defaultProps = {
  className: null,
  size: 20,
  heightIcon: 20,
}

export default OfficeIcon
