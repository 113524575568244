class Tenant {
  constructor(id, code, label) {
    this.id = id
    this.code = code
    this.label = label
  }

  static fromJson({ id, code, label } = {}) {
    return new Tenant(id, code, label)
  }
}

export default Tenant
