import c from 'classnames'
import PropTypes from 'prop-types'
import styles from './filterIcon.module.scss'

const FilterIcon = ({ className, size }) => {
  return (
    <svg
      className={c(styles.icon, className)}
      height={size}
      width={size}
      viewBox="0 0 800 800"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 416c0 4.4 3.6 8 8 8l88.5 0c3.9 31.6 30.9 56 63.5 56s59.6-24.4 63.5-56L504 424c4.4 0 8-3.6 8-8s-3.6-8-8-8l-280.5 0c-3.9-31.6-30.9-56-63.5-56s-59.6 24.4-63.5 56L8 408c-4.4 0-8 3.6-8 8zm112 0a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zM304 256a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm48-64c-32.6 0-59.6 24.4-63.5 56L8 248c-4.4 0-8 3.6-8 8s3.6 8 8 8l280.5 0c3.9 31.6 30.9 56 63.5 56s59.6-24.4 63.5-56l88.5 0c4.4 0 8-3.6 8-8s-3.6-8-8-8l-88.5 0c-3.9-31.6-30.9-56-63.5-56zM192 144a48 48 0 1 1 0-96 48 48 0 1 1 0 96zm63.5-56c-3.9-31.6-30.9-56-63.5-56s-59.6 24.4-63.5 56L8 88c-4.4 0-8 3.6-8 8s3.6 8 8 8l120.5 0c3.9 31.6 30.9 56 63.5 56s59.6-24.4 63.5-56L504 104c4.4 0 8-3.6 8-8s-3.6-8-8-8L255.5 88z"
        fill="#0570EE"
        transform="translate(150, 200)"
      />
    </svg>
  )
}

FilterIcon.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number,
}

FilterIcon.defaultProps = {
  className: null,
  size: 20,
}

export default FilterIcon
