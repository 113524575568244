import { action, makeObservable, observable, runInAction } from 'mobx'
import Settings from 'models/Settings'
import SettingsService from 'services/SettingsService'
import { AsyncStore } from 'subFramework'

class SettingsStore extends AsyncStore {
  constructor() {
    super()
    this.settings = new Settings()
    this.settingsService = new SettingsService()

    this.makeObservables()
  }

  setSettings(settings) {
    runInAction(() => {
      this.settings = settings
    })
  }

  async getSettings() {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      this.preRequest()

      try {
        const settings = await this.settingsService.getSettings()

        runInAction(() => {
          this.setSettings(settings)

          this.onSuccessRequest()
          resolve()
        })
      } catch (e) {
        runInAction(() => {
          this.onErrorRequest(e)
          reject()
        })
      }
    })
  }

  makeObservables() {
    makeObservable(this, {
      // observables
      settings: observable,
      // actions
      getSettings: action,
      // computeds
    })
  }
}

export default SettingsStore
