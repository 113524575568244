import i18next from 'i18next'
import { ASSIGNED_STATUS, PLANNED_STATUS } from 'models/PlanningStatus'
import { lazy } from 'react'
import * as paths from './paths'
import * as permissions from './permissions'

const SignInComponent = lazy(() =>
  import(/* webpackChunkName: "SignInComponent" */ 'scenes/SignIn')
)

// const ForgotPasswordComponent = lazy(() =>
//  import(/* webpackChunkName: "ForgotPasswordComponent" */ 'scenes/ForgotPassword')
// )

// const ResetPasswordComponent = lazy(() =>
//   import(/* webpackChunkName: "ResetPasswordComponent" */ 'scenes/ResetPassword')
// )

const AccessListComponent = lazy(() =>
  import(/* webpackChunkName: "NotFound404Component" */ 'presentation/AccessList')
)

const NotFound404Component = lazy(() =>
  import(/* webpackChunkName: "NotFound404Component" */ 'scenes/NotFound404')
)

const LoadType = lazy(() => import(/* webpackChunkName: "LoadType" */ 'scenes/LoadTypes'))

const VehiclesTypes = lazy(() =>
  import(/* webpackChunkName: "VehiclesTypes" */ 'scenes/VehiclesTypes')
)
const Vehicles = lazy(() => import(/* webpackChunkName: "Vehicles" */ 'scenes/Vehicles'))
const Suppliers = lazy(() => import(/* webpackChunkName: "Suppliers" */ 'scenes/Suppliers'))

const MapComponent = lazy(() => import(/* webpackChunkName: "MapComponent" */ 'scenes/Map'))

const UsersTrackingComponent = lazy(() =>
  import(/* webpackChunkName: "UsersTrackingComponent" */ 'scenes/UsersTracking')
)

const LiveWorkOrdersComponent = lazy(() =>
  import(/* webpackChunkName: "LiveWorkOrdersComponent" */ 'scenes/LiveWorkOrders')
)

const LogoutComponent = lazy(() =>
  import(/* webpackChunkName: "LogoutComponent" */ 'scenes/Logout')
)

const OperatorsListComponent = lazy(() =>
  import(/* webpackChunkName: "OperatorsListComponent" */ 'scenes/OperatorsList')
)

const OperatorFormComponent = lazy(() =>
  import(/* webpackChunkName:  "OperatorFormComponent" */ 'scenes/OperatorForm')
)

const RolesListComponent = lazy(() =>
  import(/* webpackChunkName: "RolesListComponent" */ 'scenes/RolesList')
)

const RoleFormComponent = lazy(() =>
  import(/* webpackChunkName: "RoleFormComponent" */ 'scenes/RoleForm')
)

const ExecutorsListComponent = lazy(() =>
  import(/* webpackChunkName: "ExecutorsListComponent" */ 'scenes/ExecutorsList')
)

const ExecutorFormComponent = lazy(() =>
  import(/* webpackChunkName: "ExecutorFormComponent" */ 'scenes/ExecutorForm')
)

const WorkOrdersListComponent = lazy(() =>
  import(/* webpackChunkName: "WorkOrdersListComponent" */ 'scenes/WorkOrdersList')
)

const OfficeListComponent = lazy(() =>
  import(/* webpackChunkName: "OfficeListComponent" */ 'scenes/OfficeList')
)
const OfficeFormComponent = lazy(() =>
  import(/* webpackChunkName: "OfficeFormComponent" */ 'scenes/OfficeForm')
)
const TrackingComponent = lazy(() =>
  import(/* webpackChunkName: "TrackingComponent" */ 'scenes/Tracking')
)
const TrackingMenuComponent = lazy(() =>
  import(/* webpackChunkName: "TrackingComponent" */ 'scenes/TrackingMenu/TrackingMenu')
)

const ClientsListComponent = lazy(() =>
  import(/* webpackChunkName: "ClientsListComponent" */ 'scenes/ClientsList')
)

const ClientFormComponent = lazy(() =>
  import(/* webpackChunkName: "ClientFormComponent" */ 'scenes/ClientForm')
)

const VehiclesListComponent = lazy(() =>
  import(/* webpackChunkName: "VehiclesListComponent" */ 'scenes/VehiclesList')
)

const VehicleFormComponent = lazy(() =>
  import(/* webpackChunkName: "VehicleFormComponent" */ 'scenes/VehicleForm')
)

const BranchFormComponent = lazy(() =>
  import(/* webpackChunkName: "BranchFormComponent" */ 'scenes/BranchForm')
)

const ZoneFormComponent = lazy(() =>
  import(/* webpackChunkName: "ZoneFormComponent" */ 'scenes/ZoneForm')
)

const GlobalParametersFormComponent = lazy(() =>
  import(/* webpackChunkName: "GlobalParametersFormComponent" */ 'scenes/GlobalParametersForm')
)

const TaskDetailComponent = lazy(() =>
  import(/* webpackChunkName: "TaskComponent" */ 'scenes/TaskDetail')
)

const WorkOrderComponent = lazy(() =>
  import(/* webpackChunkName: "WorkOrderComponent" */ 'stores/WorkOrder')
)

const TasksListComponent = lazy(() =>
  import(/* webpackChunkName: "TasksListComponent" */ 'scenes/TasksList')
)

const TasksAccountingListComponent = lazy(() =>
  import(/* webpackChunkName: "TasksAccountingListComponent" */ 'scenes/TasksAccountingList')
)

// const TaskAccountingComponent = lazy(() =>
//   import(/* webpackChunkName: "TaskAccountingComponent" */ 'scenes/TaskAccountingDetail')
// )

const CreateWorkOrderComponent = lazy(() =>
  import(/* webpackChunkName: "CreateWorkOrderComponent" */ 'scenes/CreateWorkOrder')
)

const PlannerComponent = lazy(() =>
  import(/* webpackChunkName: "PlannerComponent" */ 'scenes/Planner')
)

const PlanningsListComponent = lazy(() =>
  import(/* webpackChunkName: "PlanningsListComponent" */ 'scenes/PlanningsList')
)

const PlanningComponent = lazy(() =>
  import(/* webpackChunkName: "PlanningComponent" */ 'scenes/Planning')
)

const AnalyticsTasks = lazy(() =>
  import(/* webpackChunkName: "AnalyticsTasks" */ 'scenes/AnalyticsTasks')
)

const AnalyticsAccounting = lazy(() =>
  import(/* webpackChunkName: "AnalyticsAccounting" */ 'scenes/AnalyticsAccounting')
)

const ZonesComponent = lazy(() => import(/* webpackChunkName: "ZonesComponent" */ 'scenes/Zones'))

const DevCreateWorkOrdersComponent = lazy(() =>
  import(/* webpackChunkName: "DevCreateWorkOrdersComponent" */ 'scenes/DevCreateWorkOrders')
)

const publicRoutes = [
  {
    path: paths.LOGIN,
    name: 'signIn',
    exact: true,
    redirectPath: null,
    component: <SignInComponent />,
  },
  {
    path: paths.TRACKING,
    name: 'tracking',
    exact: true,
    redirectPath: null,
    component: <TrackingComponent />,
  },
  /* {
    path: paths.FORGOT_PASSWORD,
    name: 'forgotPassword',
    exact: true,
    redirectPath: null,
    component: <ForgotPasswordComponent />,
  }, */
  // {
  //   path: paths.RESET_PASSWORD,
  //   name: 'resetPassword',
  //   permission: 'yes',
  //   redirectPath: null,
  //   exact: true,
  //   component: <ResetPasswordComponent />,
  // },
]

const privateRoutes = [
  {
    component: <TrackingMenuComponent />,
    path: paths.TRACKING_MENU,
    name: 'trackingMenu',
    permission: permissions.VIEW_TASK,
    exact: true,
  },
  {
    component: <MapComponent />,
    path: paths.DASHBOARD,
    name: 'dashboard',
    permission: permissions.VIEW_MAP,
    exact: true,
  },
  {
    component: <UsersTrackingComponent />,
    path: paths.USERSTRACKING,
    name: '',
    permission: permissions.VIEW_MAP,
    exact: true,
  },
  {
    component: <LiveWorkOrdersComponent />,
    path: paths.LIVE_WORK_ORDERS,
    name: 'liveWorkOrders',
    permission: permissions.VIEW_LIVE_WORK_ORDERS,
    exact: true,
  },
  {
    component: <LogoutComponent />,
    path: paths.LOGOUT,
    name: 'logout',
    permission: 'yes',
    exact: true,
  },
  {
    component: <OperatorsListComponent />,
    path: paths.OPERATORS_LIST,
    name: 'operatorsList',
    permission: permissions.LIST_OPERATORS,
    exact: true,
  },
  {
    component: <OperatorFormComponent />,
    path: paths.CREATE_OPERATOR,
    name: 'createOperator',
    permission: permissions.CREATE_OPERATOR,
    exact: true,
  },
  {
    component: <OperatorFormComponent edit />,
    path: paths.EDIT_OPERATOR,
    name: 'editOperator',
    permission: permissions.EDIT_OPERATOR,
    exact: true,
  },
  {
    component: <OperatorFormComponent />,
    path: paths.VIEW_OPERATOR,
    name: 'viewOperator',
    permission: permissions.VIEW_OPERATOR,
    exact: true,
  },
  {
    component: <ZonesComponent />,
    path: paths.ZONES,
    name: 'zones',
    permission: permissions.ZONES_LIST,
    exact: true,
  },
  {
    component: <ClientsListComponent />,
    path: paths.CLIENTS_LIST,
    name: 'clientsList',
    permission: permissions.LIST_CLIENTS,
    exact: true,
  },
  {
    component: <ClientFormComponent />,
    path: paths.VIEW_CLIENT,
    name: 'viewClient',
    permission: permissions.VIEW_CLIENT,
    exact: true,
  },
  {
    component: <ClientFormComponent />,
    path: paths.CREATE_CLIENT,
    name: 'createClient',
    permission: permissions.CREATE_CLIENT,
    exact: true,
  },
  {
    component: <ClientFormComponent edit />,
    path: paths.EDIT_CLIENT,
    name: 'editClient',
    permission: permissions.EDIT_CLIENT,
    exact: true,
  },
  {
    component: <VehiclesListComponent />,
    path: paths.VEHICLES_LIST,
    name: 'clientsList',
    permission: permissions.LIST_VEHICLES,
    exact: true,
  },
  {
    component: <VehicleFormComponent />,
    path: paths.VIEW_VEHICLE,
    name: 'viewVehicle',
    permission: permissions.VIEW_VEHICLE,
    exact: true,
  },
  {
    component: <VehicleFormComponent />,
    path: paths.CREATE_VEHICLE,
    name: 'createVehicle',
    permission: permissions.CREATE_VEHICLE,
    exact: true,
  },
  {
    component: <VehicleFormComponent edit />,
    path: paths.EDIT_VEHICLE,
    name: 'editVehicle',
    permission: permissions.EDIT_VEHICLE,
    exact: true,
  },
  {
    component: <ZoneFormComponent />,
    path: paths.VIEW_ZONE,
    name: 'viewZone',
    permission: permissions.VIEW_ZONE,
    exact: true,
  },
  {
    component: <GlobalParametersFormComponent />,
    path: paths.GLOBAL_PARAMETERS,
    name: 'globalParameters',
    permission: permissions.GLOBAL_PARAMETERS,
    exact: true,
  },
  {
    component: <GlobalParametersFormComponent edit />,
    path: paths.EDIT_GLOBAL_PARAMETERS,
    name: 'editGlobalParameters',
    permission: permissions.EDIT_GLOBAL_PARAMETERS,
    exact: true,
  },
  {
    component: <ZoneFormComponent />,
    path: paths.CREATE_ZONE,
    name: 'createZone',
    permission: permissions.CREATE_ZONE,
    exact: true,
  },
  {
    component: <ZoneFormComponent edit />,
    path: paths.EDIT_ZONE,
    name: 'editZone',
    permission: permissions.EDIT_ZONE,
    exact: true,
  },
  {
    component: <BranchFormComponent />,
    path: paths.VIEW_BRANCH,
    name: 'viewClient',
    permission: permissions.VIEW_BRANCH,
    exact: true,
  },
  {
    component: <BranchFormComponent />,
    path: paths.CREATE_BRANCH,
    name: 'createClient',
    permission: permissions.CREATE_BRANCH,
    exact: true,
  },
  {
    component: <BranchFormComponent edit />,
    path: paths.EDIT_BRANCH,
    name: 'editClient',
    permission: permissions.EDIT_BRANCH,
    exact: true,
  },
  {
    component: <RolesListComponent />,
    path: paths.ROLES_LIST,
    name: 'rolesList',
    permission: permissions.LIST_ROLES,
    exact: true,
  },
  {
    component: <RoleFormComponent />,
    path: paths.CREATE_ROLE,
    name: 'createRole',
    permission: permissions.CREATE_ROLE,
    exact: true,
  },
  {
    component: <RoleFormComponent edit />,
    path: paths.EDIT_ROLE,
    name: 'editRole',
    permission: permissions.EDIT_ROLE,
    exact: true,
  },
  {
    component: <RoleFormComponent />,
    path: paths.VIEW_ROLE,
    name: 'viewRole',
    permission: permissions.VIEW_ROLE,
    exact: true,
  },
  {
    component: <ExecutorsListComponent />,
    path: paths.EXECUTORS_LIST,
    name: 'executorsList',
    permission: permissions.LIST_EXECUTORS,
    exact: true,
  },
  {
    component: <ExecutorFormComponent />,
    path: paths.CREATE_EXECUTOR,
    name: 'createExecutor',
    permission: permissions.CREATE_EXECUTOR,
    exact: true,
  },
  {
    component: <ExecutorFormComponent edit />,
    path: paths.EDIT_EXECUTOR,
    name: 'editExecutor',
    permission: permissions.EDIT_EXECUTOR,
    exact: true,
  },
  {
    component: <ExecutorFormComponent />,
    path: paths.VIEW_EXECUTOR,
    name: 'viewExecutor',
    permission: permissions.VIEW_EXECUTOR,
    exact: true,
  },
  {
    component: <WorkOrdersListComponent />,
    path: paths.WORK_ORDERS_LIST,
    name: 'workOrders',
    permission: permissions.WORK_ORDERS_LIST,
    exact: true,
  },
  {
    component: <WorkOrderComponent />,
    path: paths.WORK_ORDER,
    name: 'workOrders',
    permission: permissions.VIEW_WORK_ORDER,
    exact: true,
  },
  {
    component: <CreateWorkOrderComponent />,
    path: paths.WORK_ORDER_CREATE,
    name: 'workOrders',
    permission: permissions.CREATE_WORK_ORDER,
    exact: true,
  },
  {
    component: <OfficeListComponent />,
    path: paths.OFFICES_LIST,
    name: 'office',
    permission: permissions.OFFICES_LIST,
    exact: true,
  },
  {
    component: <AccessListComponent />,
    path: paths.ACCESS_LIST,
    name: 'access',
    permission: permissions.OFFICES_LIST,
    exact: true,
  },
  {
    component: <OfficeFormComponent />,
    path: paths.VIEW_OFFICE,
    name: 'office',
    permission: permissions.VIEW_OFFICE,
    exact: true,
  },
  {
    component: <OfficeFormComponent />,
    path: paths.CREATE_OFFICE,
    name: 'office',
    permission: permissions.CREATE_OFFICE,
    exact: true,
  },
  {
    component: <OfficeFormComponent edit />,
    path: paths.EDIT_OFFICE,
    name: 'office',
    permission: permissions.EDIT_OFFICE,
    exact: true,
  },
  {
    component: <TasksListComponent />,
    path: paths.TASKS_LIST,
    name: 'tasksList',
    permission: permissions.TASKS_LIST,
    exact: true,
  },
  {
    component: <TasksAccountingListComponent />,
    path: paths.TASKS_ACCOUNTING_LIST,
    name: 'tasksList',
    permission: permissions.TASKS_ACCOUNTING_LIST,
    exact: true,
  },
  {
    component: <TaskDetailComponent isAccounting />,
    path: paths.TASK_ACCOUNTING,
    name: 'taskDetail',
    permission: permissions.VIEW_TASK,
    exact: true,
  },
  {
    component: <TaskDetailComponent title={i18next.t('accounting')} />,
    path: paths.TASK_DETAIL,
    name: 'taskDetail',
    permission: permissions.VIEW_TASK,
    exact: true,
  },
  {
    component: <PlannerComponent />,
    path: paths.PLANNER,
    name: 'planner',
    permission: permissions.MENU_PLANNER,
    exact: true,
  },
  {
    component: <PlanningsListComponent />,
    path: paths.PLANNINGS_LIST,
    name: 'planningsList',
    permission: permissions.MENU_PLANNINGS,
    exact: true,
  },
  {
    component: <PlanningsListComponent status={ASSIGNED_STATUS} />,
    path: paths.ROUTER,
    name: 'router',
    permission: permissions.MENU_ROUTER,
    exact: true,
  },
  {
    component: <PlanningsListComponent status={PLANNED_STATUS} />,
    path: paths.ASSIGNER,
    name: 'router',
    permission: permissions.MENU_ASSIGNER,
    exact: true,
  },
  {
    component: <PlanningComponent />,
    path: paths.PLANNING,
    name: 'planningsList',
    permission: permissions.PLANNING,
    exact: true,
  },
  {
    component: <PlanningComponent />,
    path: paths.ROUTING_PLANNING,
    name: 'planningsList',
    permission: permissions.VIEW_ROUTING_PLANNINGS,
    exact: true,
  },
  {
    component: <PlanningComponent />,
    path: paths.ASSIGNER_PLANNING,
    name: 'planningsList',
    permission: permissions.VIEW_ASSIGNMENT_PLANNINGS,
    exact: true,
  },
  {
    component: <AnalyticsTasks />,
    path: paths.ANALYTICS_TASKS,
    name: 'analyticsTasks',
    permission: 'yes',
    exact: true,
  },
  {
    component: <AnalyticsAccounting />,
    path: paths.ANALYTICS_ACCOUNTING,
    name: 'planningsList',
    permission: 'yes',
    exact: true,
  },
  {
    component: <NotFound404Component />,
    path: '*',
    name: 'notFound404',
    permission: 'yes',
    exact: true,
  },
  {
    component: <LoadType />,
    path: 'tipo-cargas',
    name: 'LoadType',
    permission: 'yes',
    exact: true,
  },
  {
    component: <VehiclesTypes />,
    path: 'tipo-vehiculos',
    name: 'VehiclesTypes',
    permission: 'yes',
    exact: true,
  },
  {
    component: <Vehicles />,
    path: paths.VEHICLES,
    name: 'Vehicles',
    permission: 'yes',
    exact: true,
  },
  {
    component: <Suppliers />,
    path: 'proveedores',
    name: 'Suppliers',
    permission: 'yes',
    exact: true,
  },
]

const getPrivateRouteFromPath = (path) => {
  let privateRoute = null

  privateRoutes.forEach((route) => {
    if (route.path === path) {
      privateRoute = route
    }
  })

  return privateRoute
}

if (process.env.REACT_APP_ENABLED_CREATE_WORK_ORDERS === 'true') {
  privateRoutes.push({
    component: <DevCreateWorkOrdersComponent />,
    path: paths.DEV_CREATE_WORK_ORDERS,
    name: 'devCreateWorkOrders',
    permission: 'yes',
    exact: true,
  })
}

export default { publicRoutes, privateRoutes, getPrivateRouteFromPath }
