import { AuthStore as AuthStoreSubFramework } from 'subFramework'
import AuthService from 'services/AuthService'
import * as paths from 'routing/paths'
import routes from 'routing/routes'

const DASHBOARD_PATHS = [
  paths.DASHBOARD,
  paths.PLANNER,
  paths.PLANNINGS_LIST,
  paths.ASSIGNER,
  paths.ROUTER,
  paths.ZONES,
  paths.GLOBAL_PARAMETERS,
  paths.VEHICLES_LIST,
  paths.WORK_ORDERS_LIST,
  paths.TASKS_LIST,
  paths.TASKS_ACCOUNTING_LIST,
  paths.CLIENTS_LIST,
  paths.EXECUTORS_LIST,
  paths.OPERATORS_LIST,
  paths.ROLES_LIST,
  paths.LIVE_WORK_ORDERS,
]

class AuthStore extends AuthStoreSubFramework {
  constructor() {
    super(new AuthService())

    this.loginRedirectRoute = null
  }

  get dashboardRoute() {
    let path = null

    DASHBOARD_PATHS.forEach((dashBoardPath) => {
      const route = routes.getPrivateRouteFromPath(dashBoardPath)
      if (!path && route && this.can(route.permission)) {
        path = dashBoardPath
      }
    })

    return path || DASHBOARD_PATHS[0]
  }
}

export default AuthStore
