export const VIEW_MAP = 'map.view'
export const VIEW_LIVE_WORK_ORDERS = 'work_orders.view_live'
export const LIST_OPERATORS = 'operators.list'
export const CREATE_OPERATOR = 'operators.create'
export const EDIT_OPERATOR = 'operators.edit'
export const VIEW_OPERATOR = 'operators.view'
export const ACTIVATE_OPERATOR = 'operators.activate'
export const DEACTIVATE_OPERATOR = 'operators.deactivate'
export const LIST_EXECUTORS = 'executors.list'
export const CREATE_EXECUTOR = 'executors.create'
export const EDIT_EXECUTOR = 'executors.edit'
export const VIEW_EXECUTOR = 'executors.view'
export const ACTIVATE_EXECUTOR = 'executors.activate'
export const DEACTIVATE_EXECUTOR = 'executors.deactivate'
export const OFFICES_LIST = 'offices.list'
export const CREATE_OFFICE = 'offices.create'
export const EDIT_OFFICE = 'offices.edit'
export const VIEW_OFFICE = 'offices.view'
export const WORK_ORDERS_LIST = 'work_orders.list'
export const VIEW_WORK_ORDER = 'work_orders.view'
export const EXPORT_WORK_ORDERS = 'work_orders.export'
export const IMPORT_WORK_ORDERS = 'work_orders.import'
export const CANCEL_WORK_ORDERS = 'work_orders.cancel'
export const DELETE_WORK_ORDERS = 'work_orders.delete'
export const ACTIVATE_WORK_ORDERS = 'work_orders.enable'
export const CREATE_WORK_ORDER = 'work_orders.create'
export const TRANSFER_WORK_ORDERS = 'work_orders.transfer'
export const COMMENT_WORK_ORDERS = 'work_orders.comment'
export const TASKS_LIST = 'tasks.list'
export const VIEW_TASK = 'tasks.view'
export const KPIS_TASKS = 'tasks.kpis'
export const EXPORT_TASKS = 'tasks.export'
export const CANCEL_TASKS = 'tasks.cancel'
export const TRANSFER_TASKS = 'tasks.transfer'
export const CHANGE_STATUS_TASK = 'tasks.changeFinishStatus'
export const FINISH_TASKS = 'tasks.finish'
export const EDIT_TASKS = 'tasks.edit'
export const CREATE_TASKS = 'tasks.create'
export const REORDER_TASKS = 'tasks.order'
export const EXPORT_TASKS_ACCOUNTING = 'accounting.export'
export const EXPORT_TASK_ACCOUNTING = 'accounting.export'
export const LIST_ROLES = 'roles.list'
export const CREATE_ROLE = 'roles.create'
export const EDIT_ROLE = 'roles.edit'
export const VIEW_ROLE = 'roles.view'
export const DELETE_ROLE = 'roles.delete'
export const LIST_CLIENTS = 'clients.list'
export const CREATE_CLIENT = 'clients.create'
export const EDIT_CLIENT = 'clients.edit'
export const VIEW_CLIENT = 'clients.view'
export const IMPORT_CLIENTS = 'yes'
export const ACTIVATE_CLIENT = 'clients.activate'
export const DEACTIVATE_CLIENT = 'clients.deactivate'
export const DELETE_CLIENT = 'clients.delete'
export const LIST_BRANCHES = 'offices.create'
export const CREATE_BRANCH = 'offices.create'
export const EDIT_BRANCH = 'offices.edit'
export const VIEW_BRANCH = 'offices.view'
export const DELETE_BRANCH = 'offices.delete'
export const ACTIVATE_BRANCH = 'offices.activate'
export const DEACTIVATE_BRANCH = 'offices.deactivate'
export const ZONES_LIST = 'zones.list'
export const CREATE_ZONE = 'zones.create'
export const IMPORT_ZONE = 'zones.import'
export const EDIT_ZONE = 'zones.edit'
export const VIEW_ZONE = 'zones.view'
export const DELETE_ZONE = 'zones.delete'
export const GLOBAL_PARAMETERS = 'settings.list'
export const EDIT_GLOBAL_PARAMETERS = 'settings.edit'

export const MENU_PLANNER = 'menu.planner.enable'
export const MENU_PLANNINGS = 'menu.planning_history.enable'
export const MENU_ASSIGNER = 'menu.assigner.enable'
export const MENU_ROUTER = 'menu.router.enable'

export const VIEW_DRAFT_ORDERS = 'orders.draft.view'
export const VIEW_ELIGIBLE_ORDERS = 'orders.eligible.view'
export const VIEW_IN_PLANNING_ORDERS = 'orders.in_planning.view'
export const VIEW_IN_ASSIGNMENT_ORDERS = 'orders.assignment.view'
export const VIEW_IN_ROUTING_ORDERS = 'orders.in_routing.view'
export const VIEW_FINISHED_ORDERS = 'orders.planned.view'

export const CREATE_ORDERS = 'orders.create'
export const DELETE_ORDERS = 'orders.delete'
export const IMPORT_ORDERS = 'orders.import'
export const EDIT_ORDERS = 'orders.edit'
export const KPIS_ORDERS = 'planning.kpis.view'

export const CHANGE_PENDING_PLANNING_TO_ASSIGNMENT = 'planning.auto_start'
export const CHANGE_ROUTING_PLANNING_TO_FINISHED = 'planning.finish'
export const CHANGE_ASSIGNMENT_PLANNING_TO_ROUTING = 'planning.route'

export const CREATE_PLANNING = 'planning.create'

export const VIEW_PENDING_PLANNINGS = 'plannings.pending.view'
export const VIEW_ASSIGNMENT_PLANNINGS = 'plannings.assignment.view'
export const VIEW_ROUTING_PLANNINGS = 'plannings.routing.view'
export const VIEW_FINISHED_PLANNINGS = 'plannings.finalized.view'

export const REGROUP_IN_ASSIGNMENT_PLANNING = 'orders.assignment.regroup'
export const REGROUP_IN_ROUTING_PLANNING = 'orders.routing.regroup'

export const DELETE_PENDING_PLANNING = 'plannings.pending.delete'
export const DELETE_ASSIGNMENT_PLANNING = 'plannings.assignment.delete'
export const DELETE_ROUTING_PLANNING = 'plannings.routing.delete'

export const ADD_ORDERS_TO_PENDING_PLANNING = 'orders.pending.add'
export const ADD_ORDERS_TO_ASSIGNMENT_PLANNING = 'orders.assignment.add'
export const ADD_ORDERS_TO_ROUTING_PLANNING = 'orders.routing.add'

export const UN_PLAN_ORDERS_IN_PENDING_PLANNING = 'orders.pending.unplan'
export const UN_PLAN_ORDERS_IN_ASSIGNMENT_PLANNING = 'orders.assignment.unplan'
export const UN_PLAN_ORDERS_IN_ROUTING_PLANNING = 'orders.routing.unplan'

export const DELETE_ROUTE_IN_ASSIGNMENT_PLANNING = 'route.assignment.delete'
export const DELETE_ROUTE_IN_ROUTING_PLANNING = 'route.routing.delete'

export const ASSIGN_VEHICLE_IN_ASSIGNMENT_PLANNING = 'vehicle.assignment.assign'
export const ASSIGN_VEHICLE_IN_ROUTING_PLANNING = 'vehicle.routing.assign'

export const ASSIGN_EXECUTOR_IN_ASSIGNMENT_PLANNING = 'executor.assignment.assign'
export const ASSIGN_EXECUTOR_IN_ROUTING_PLANNING = 'executor.routing.assign'

export const VALIDATE_ROUTES = 'routes.validate'

export const CREATE_ROUTE_IN_ASSIGNMENT_PLANNING = 'route.assignment.create'
export const CREATE_ROUTE_IN_ROUTING_PLANNING = 'route.routing.create'

export const PLANNING = 'yes'
export const DELETE_PLANNINGS = 'planning.delete'
export const LIST_VEHICLES = 'vehicles.list'
export const CREATE_VEHICLE = 'vehicles.create'
export const IMPORT_VEHICLES = 'vehicles.import'
export const EDIT_VEHICLE = 'vehicles.edit'
export const VIEW_VEHICLE = 'vehicles.view'
export const DELETE_VEHICLE = 'vehicles.delete'
export const VIEW_ACCOUNTING_PAYMENT = 'accounting.view_payments'
export const VIEW_ACCOUNTING_REFUND = 'accounting.view_refunds'
export const VIEW_ACCOUNTING_REJECT = 'yes'
export const VIEW_ACCOUNTING_MISSING = 'accounting.view_missings'
export const CHANGE_ACCOUNTING_PAYMENT_STATUS = 'accounting.validate_payments'
export const CHANGE_ACCOUNTING_REFUND_STATUS = 'accounting.validate_refunds'
// export const CHANGE_ACCOUNTING_REJECT_STATUS = 'accounting.validate_rejects'
export const CHANGE_ACCOUNTING_REJECT_STATUS = 'yes'
export const TASKS_ACCOUNTING_LIST = 'accounting.view'
export const TASK_ACCOUNTING = 'tasks.view'
export const CHANGE_ACCOUNTING_MISSING_STATUS = 'accounting.validate_missings'
export const PRINT_TASK_DETAIL = 'yes'

export const CREATE_EXPECTED_REFUNDS = 'accounting.create_expected_refunds'
export const EDIT_EXPECTED_REFUNDS = 'accounting.edit_expected_refunds'
export const VIEW_EXPECTED_REFUNDS = 'accounting.view_expected_refunds'
export const DELETE_EXPECTED_REFUNDS = 'DISABLED.accounting.delete_expected_refunds'

export const CREATE_EXPECTED_PAYMENTS = 'accounting.create_adjustments'
export const EDIT_EXPECTED_PAYMENTS = 'accounting.edit_adjustments'
export const ANALYTICS_VIEW_TASKS = 'analytics.view_kpis_tasks'
export const ANALYTICS_VIEW_ACCOUNTING = 'analytics.view_kpis_accounting'

export default {
  VIEW_MAP,
  VIEW_LIVE_WORK_ORDERS,
  LIST_OPERATORS,
  CREATE_OPERATOR,
  EDIT_OPERATOR,
  VIEW_OPERATOR,
  LIST_EXECUTORS,
  CREATE_EXECUTOR,
  EDIT_EXECUTOR,
  VIEW_EXECUTOR,
  WORK_ORDERS_LIST,
  VIEW_WORK_ORDER,
  OFFICES_LIST,
  CREATE_OFFICE,
  EDIT_OFFICE,
  VIEW_OFFICE,
  TASKS_LIST,
  VIEW_TASK,
  TRANSFER_TASKS,
  LIST_ROLES,
  CREATE_ROLE,
  EDIT_ROLE,
  VIEW_ROLE,
  DELETE_ROLE,
  FINISH_TASKS,
  ANALYTICS_VIEW_TASKS,
  ANALYTICS_VIEW_ACCOUNTING,
}
