import { useState } from 'react'
import { observer } from 'mobx-react'
import { LoadingRing, RootStore, StoreContext } from 'subFramework'
import { getDomain } from 'services/config'
import AuthStore from 'stores/AuthStore'
import axiosInterceptors from 'util/axiosInterceptors'
import Router from 'routing'
import 'util/i18n'
import 'styles/base.module.scss'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import SettingsStore from './stores/SettingsStore'

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  })
}

const App = () => {
  const domain = getDomain()
  const [rootStore] = useState(() => new RootStore(new AuthStore(), new SettingsStore(), domain))
  axiosInterceptors(rootStore)

  // hows application loading if auth is being fetched
  if (rootStore?.authStore.isLoading) {
    return <LoadingRing center />
  }

  return (
    <StoreContext.Provider value={rootStore}>
      <Router />
    </StoreContext.Provider>
  )
}

export default observer(App)
