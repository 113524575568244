class TableColumn {
  constructor(key, visible) {
    this.key = key
    this.visible = visible
  }

  static fromJson({ key, visible }) {
    return new TableColumn(key, visible)
  }
}

export default TableColumn
