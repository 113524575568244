import PropTypes from 'prop-types'
import { memo } from 'react'
import { LoadingRing, TablePagination } from 'subFramework'
import DynamicTableColumn from 'subFramework/models/DynamicTableColumn'
import Paginator from 'subFramework/stores/Paginator'
import Sorter from 'subFramework/stores/Sorter'
import DynamicTableContent from './DynamicTableContent'
import DynamicTableHeader from './DynamicTableHeader'
import styles from './dynamicTableStyles.module.scss'

const DynamicTable = ({
  displayResultsMessage,
  sortDescendingIcon,
  sortAscendingIcon,
  paginatorPrevIcon,
  paginatorNextIcon,
  handlePageChange,
  handleSortChange,
  noResultsMessage,
  handleRowClick,
  paginator,
  isLoading,
  columns,
  sorter,
  data,
  clickRow,
}) => {
  if (isLoading) {
    return <LoadingRing center small />
  }

  const gridStyle = {
    gridTemplateColumns: columns.map(({ size = '1fr' }) => `${size}`).join(' '),
  }

  const tableHeader = (
    <DynamicTableHeader
      sortDescendingIcon={sortDescendingIcon}
      sortAscendingIcon={sortAscendingIcon}
      handleSortChange={handleSortChange}
      gridStyle={gridStyle}
      columns={columns}
      isLoading={isLoading}
      sorter={sorter}
    />
  )

  let tableContent = noResultsMessage

  if (data?.length > 0) {
    tableContent = (
      <DynamicTableContent
        isLoading={isLoading}
        data={data}
        handleRowClick={handleRowClick}
        columns={columns}
        gridStyle={gridStyle}
        clickRow={clickRow}
      />
    )
  }

  return (
    <>
      <div className={styles.table}>
        {tableHeader}
        {tableContent}
      </div>
      <TablePagination
        displayResultsMessage={displayResultsMessage}
        handlePageChange={handlePageChange}
        prevIcon={paginatorPrevIcon}
        nextIcon={paginatorNextIcon}
        paginator={paginator}
        isLoading={isLoading}
      />
    </>
  )
}

DynamicTable.propTypes = {
  noResultsMessage: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  columns: PropTypes.arrayOf(PropTypes.instanceOf(DynamicTableColumn)),
  data: PropTypes.oneOfType([PropTypes.array]),
  paginator: PropTypes.instanceOf(Paginator).isRequired,
  displayResultsMessage: PropTypes.string,
  sorter: PropTypes.instanceOf(Sorter),
  sortAscendingIcon: PropTypes.node,
  sortDescendingIcon: PropTypes.node,
  paginatorPrevIcon: PropTypes.node,
  paginatorNextIcon: PropTypes.node,
  handlePageChange: PropTypes.func,
  handleSortChange: PropTypes.func,
  handleRowClick: PropTypes.func,
  isLoading: PropTypes.bool,
  clickRow: PropTypes.bool,
}

DynamicTable.defaultProps = {
  displayResultsMessage: null,
  handlePageChange: () => {},
  handleSortChange: () => {},
  handleRowClick: () => {},
  sortDescendingIcon: null,
  sortAscendingIcon: null,
  paginatorPrevIcon: null,
  paginatorNextIcon: null,
  sorter: Sorter.empty(),
  isLoading: false,
  columns: [],
  data: [],
  clickRow: false,
}

export default memo(DynamicTable)
