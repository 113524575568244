import axios from 'axios'
import Cookies from 'js-cookie'
import AuthUser from '../models/AuthUser'

class AuthService {
  /** returns an AuthUser, this function should be implemented by the children class to customize AuthUser data * */
  getAuthUserFromJson = (data) => AuthUser.fromJson(data)

  getAuthUserFromCookie = (data) => AuthUser.fromCookie(data)

  /** sets whatever is needed for loadAuthUserFromBrowser to work * */
  persistLoginData = (authUser) => {
    Cookies.set('authUser', JSON.stringify({ ...authUser, permissions: [] }))
  }

  processAuthData = (data) => {
    const authUser = this.getAuthUserFromJson(data.data)

    this.persistLoginData(authUser)

    return authUser
  }

  /** sets whatever is needed for loadAuthUserFromBrowser to work * */
  getStoredLoginData = () => Cookies.get('authUser')

  /** removes whatever was setted in persistLoginData * */
  removePersistedData = () => {
    Cookies.remove('authUser')
  }

  /** should return the athentication api endpoint url * */
  getAuthenticateUri = () => '/api/authentication/authenticate'

  /** should return the logout api endpoint url * */
  getLogoutUri = () => '/api/authentication/logout'

  authenticate = (email, password, domain) => {
    return axios
      .post(this.getAuthenticateUri(), {
        email,
        password,
        tenantProvider: 'domain',
        platform: 'backoffice',
        tenant: domain,
      })
      .then(({ data }) => {
        return this.processAuthData(data)
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.log(e)
      })
  }

  logout = () => {
    return axios
      .post(this.getLogoutUri())
      .then((response) => {
        this.removePersistedData()
        return response.data
      })
      .catch(() => {
        this.removePersistedData()
      })
  }

  loadAuthUserFromBrowser = () => {
    const authUser = this.getStoredLoginData()

    if (authUser) {
      return this.getAuthUserFromCookie(JSON.parse(authUser))
    }

    return null
  }
}

export default AuthService
