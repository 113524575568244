import { memo } from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import c from 'classnames'
import DynamicTableColumn from 'subFramework/models/DynamicTableColumn'
import Sorter from 'subFramework/stores/Sorter'
import styles from './dynamicTableStyles.module.scss'

const DynamicTableHeader = ({
  columns,
  gridStyle,
  handleSortChange,
  isLoading,
  sortAscendingIcon,
  sortDescendingIcon,
  sorter,
}) => {
  const handleClickRowCell = ({ sortable, key }) => {
    if (sortable) {
      handleSortChange(key)
    }
  }

  return (
    <div className={c(styles.rowItem, styles.headerRow)} style={gridStyle}>
      {columns.map((column, i) => {
        const { title, sortable, key } = column
        const hasSort = sortable && !isLoading

        return (
          <div
            className={c(styles.headerRowCell, hasSort && styles.hasSort)}
            onClick={() => handleClickRowCell(column)}
            role="button"
            tabIndex={0}
            // eslint-disable-next-line react/no-array-index-key
            key={i}
          >
            {title}
            {hasSort && (
              <span
                className={c(styles.sortIcon, sorter.sortField === key && styles.sortIconActive)}
              >
                {!sorter.isASC && sorter.sortField === key ? sortDescendingIcon : sortAscendingIcon}
              </span>
            )}
          </div>
        )
      })}
    </div>
  )
}

DynamicTableHeader.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.instanceOf(DynamicTableColumn)),
  gridStyle: PropTypes.oneOfType([PropTypes.any]),
  sortDescendingIcon: PropTypes.node,
  sortAscendingIcon: PropTypes.node,
  isLoading: PropTypes.bool,
  handleSortChange: PropTypes.func,
  sorter: PropTypes.instanceOf(Sorter),
}

DynamicTableHeader.defaultProps = {
  handleSortChange: () => {},
  isLoading: false,
  sortDescendingIcon: null,
  sortAscendingIcon: null,
  sorter: Sorter.empty(),
  gridStyle: {},
  columns: [],
}

export default memo(observer(DynamicTableHeader))
