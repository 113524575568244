import { getInitials } from 'util/utils'

const ACCOUNTING_MODULE = 'accounting'

class TaskType {
  constructor(id, value, modules = []) {
    this.id = id
    this.value = value
    this.modules = modules
  }

  get hasAccounting() {
    return this.modules.includes(ACCOUNTING_MODULE)
  }

  static hasAccounting(taskType, types = []) {
    const selectedType = types.filter((type) => type.id === taskType?.id)
    const type = selectedType.length > 0 ? selectedType[0] : taskType

    return type.hasAccounting
  }

  get shortType() {
    return getInitials(this.value)
  }

  get label() {
    return this.value
  }

  static fromJson({ type, typeLabel, name = type, label = typeLabel, modules }) {
    return new TaskType(name, label, modules)
  }
}

export default TaskType
