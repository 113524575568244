import { action, computed, makeObservable, observable, runInAction } from 'mobx'

class AsyncStore {
  constructor() {
    this.isLoading = true
    this.errors = []
    this.serverError = false
    this.validationError = false

    makeObservable(this, {
      // observables
      isLoading: observable,
      errors: observable,
      serverError: observable,
      // actions
      preRequest: action,
      onSuccessRequest: action,
      clearError: action,
      setServerError: action,
      finishRequest: action,
      onErrorRequest: action,
      // computeds
      hasErrors: computed,
    })
  }

  requestProcess(request = null) {
    runInAction(() => {
      this.clearError()
      this.tryAgainRequest = request
    })
  }

  tryAgain() {
    if (this.tryAgainRequest) {
      this.tryAgainRequest()
    }

    return null
  }

  preRequest(request) {
    runInAction(() => {
      this.isLoading = true
      this.errors = []
      this.requestProcess(request)
    })
  }

  onSuccessRequest() {
    runInAction(() => {
      this.isLoading = false
    })
  }

  clearError() {
    runInAction(() => {
      this.serverError = false
    })
  }

  setServerError() {
    runInAction(() => {
      this.serverError = true
    })
  }

  setValidationError() {
    runInAction(() => {
      this.validationError = true
    })
  }

  finishRequest() {
    runInAction(() => {
      this.isLoading = false
    })
  }

  onErrorRequest(error) {
    this.finishRequest()
    this.errors.push(error)

    if (AsyncStore.isServerError(error)) {
      this.setServerError(error)
    }
  }

  get hasErrors() {
    return !!this.errors.length
  }

  static isServerError(error) {
    if (error && error.response && error.response.status) {
      return error.response.status === 500
    }

    return false
  }
}

export default AsyncStore
