import React from 'react'
import c from 'classnames'
import PropTypes from 'prop-types'
import styles from './analitycsIcon.module.scss'

const AnalitycsIcon = ({ className, size }) => {
  return (
    <svg
      className={c(styles.icon, className)}
      fill="none"
      width={size}
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      height="20px"
      viewBox="0 0 70 69.991"
      enableBackground="new 0 0 70 69.991"
    >
      <image
        overflow="hidden"
        width="65"
        height="74"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEEAAABKCAQAAABOOfENAAAACXBIWXMAAAsTAAALEwEAmpwYAAAD
        GWlDQ1BQaG90b3Nob3AgSUNDIHByb2ZpbGUAAHjaY2BgnuDo4uTKJMDAUFBUUuQe5BgZERmlwH6e
        gY2BmYGBgYGBITG5uMAxIMCHgYGBIS8/L5UBA3y7xsDIwMDAcFnX0cXJlYE0wJpcUFTCwMBwgIGB
        wSgltTiZgYHhCwMDQ3p5SUEJAwNjDAMDg0hSdkEJAwNjAQMDg0h2SJAzAwNjCwMDE09JakUJAwMD
        g3N+QWVRZnpGiYKhpaWlgmNKflKqQnBlcUlqbrGCZ15yflFBflFiSWoKAwMD1A4GBgYGXpf8EgX3
        xMw8BUNTVQYqg4jIKAX08EGIIUByaVEZhMXIwMDAIMCgxeDHUMmwiuEBozRjFOM8xqdMhkwNTJeY
        NZgbme+y2LDMY2VmzWa9yubEtoldhX0mhwBHJycrZzMXM1cbNzf3RB4pnqW8xryH+IL5nvFXCwgJ
        rBZ0E3wk1CisKHxYJF2UV3SrWJw4p/hWiRRJYcmjUhXSutJPZObIhsoJyp2V71HwUeRVvKA0RTlK
        RUnltepWtUZ1Pw1Zjbea+7QmaqfqWOsK6b7SO6I/36DGMMrI0ljS+LfJPdPDZivM+y0qLBOtfKwt
        bFRtRexY7L7aP3e47XjB6ZjzXpetruvdVrov9VjkudBrgfdCn8W+y/xW+a8P2Bq4N+hY8PmQW6HP
        wr5EMEUKRilFG8e4xUbF5cW3JMxO3Jx0Nvl5KlOaXLpNRlRmVdas7D059/KY8tULfAqLi2YXHy55
        WyZR7lJRWDmv6mz131q9uvj6SQ3HGn83G7Skt85ru94h2Ond1d59uJehz76/bsK+if8nO05pnXpi
        Ou+M4JmzZj2aozW3ZN6+BVwLwxYtXvxxqcOyCcsfrjRe1br65lrddU3rb2402NSx+cFWq21Tt3/Y
        6btr1R6Oven7jh9QP9h56PURv6Obj4ufqD355LT3mS3nZM+3X/h0Ke7yqasW15bdEL3ZeuvrnfS7
        N+/7PDjwyPTx6qeKz2a+EHzZ9Zr5Td3bn+9LP3z6VPD53de8b+9+5P/88Lv4z7d/Vf//AwAqvx2K
        829RWwAAACBjSFJNAAB6JQAAgIMAAPn/AACA6AAAUggAARVYAAA6lwAAF2/XWh+QAAAEN0lEQVR4
        2uxaO2gUURQ9VyeSGFmRiOvniQpRxCIiq8QfqIgisRG0sdDCwkIFm1QBV1CIRSxsBK0tTKGFrYIL
        GomCUYka/AQiMsWGaIT1E2VHrsXM7Lw3780ybmYnzSwkbMhj3tl7zzn3vMcSY65f85BByCBkEDII
        cwDhff71+uj/UrPd8f6J72vJYQsAVpW2D6UM4V1+7BSAfgZAALjYOn34eqoQ7l6gSwAg7VHsGN17
        LzUuPDkIMPwKEAgALk1vSpGO5W63BgCDpEqMFFKDQA7Db4P/Q+D+X/nUILDlFd8FVINTbVPXWc0U
        JCtbe6pI35pkQvJcuCNJbGBjDZoMgcCeGEPSTFsRMgsCdqRER9UVSCJpaqKEsqnPCJq7vEA1fqQO
        IaoBqYmSQwbF6SoCUuejJNlURbBFChUJMJCxqRBcXwi3gJOHUDpa3v63JTexYXDjpF4Frm1J0qhK
        EMLDo8NXqm0kAMaL3mUjZ47okzKoAxnbMCs63ut9dM1ZT8L7U0wVrg6bh1RgSImOqeF9r867n9/f
        gMVPcfOOaUZQzZaQpCKGBiDYk1qN8aKM8VxnxRRdfDYk5gvjuV/54KEcTELx5rQqyqAOHOEMDVbh
        2yYWYe93f39fq89I1JFkw1Vw2iiUinzt/+7QW6BqI8FJSVqH1fMCOXLhKXJQNQxBNp16m4QNSR/W
        VuM1YIXrHCE6CgV6TrIK+nmBI1MTa5AS5ILabzYAlW2Zk6yCvB1HxhEVBCebF1iaAIpDKorQHYKS
        DPEsuV5g1Wa+kASUk2sElFDOGiHZCsc1NrZiVtkxPIDZeKDjumsSiK8ktYBCXOAQHcnYLms2m7Ny
        VuSYXsLJWRMreoizeQOR5X3+Re+XrnlOu73xln5pSUo0Z82a3Pgqr/xvX7hxp9wNAQBT+NQzZPfu
        MFkT16kBS5KNXh3ZiIHnkx4AN5z+EFfemjXPke6v1wnxT9aD/T/zEL6qCQDEn5wcTs1EI4ODcqSx
        14Xw7gRE8AjP+0S5+0MHQmdEqr03nSkRkizF9YWXXcEdmXI/Ij4fUPvMdQTHlgTfC/uxs+NkASJw
        czl0fOnyPmHVfExTLjsdMgzsmHRsn4QUueWIttA7N+ZHYLNCN78uS8aCKgT3bXJiaJmJAWHFENlG
        1Pbqkvtm3cyCSphuBAZsUfIXt1Tkw73fEOrLP4sBobOydFTd3H1Au71ltKbSEtk62xfZgYWtecBF
        1lIVWxu+xlJEzzH6JBOIwCB7x8VgxclzLRVIqndrULgarNg81jqNonyWYFBf592Y1rRu5tBx6yNs
        uQk7+3bdl9cc39o6ClsyH3vb5X3KBoevt06jWKtmkfpWPt48pom33ny7PTB+7K8FzHeWjew/pR9X
        gdsDEz3VHAAsf7bnbPiSAwCe7p4qVBeyRc7iif2DxombfX8hg5BByCBkEAyvfwMAFHGxQSHqyw0A
        AAAASUVORK5CYII="
        transform="matrix(0.9999 0 0 0.9999 0 -4)"
      />
    </svg>
  )
}

AnalitycsIcon.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number,
}

AnalitycsIcon.defaultProps = {
  className: null,
  size: 20,
}

export default AnalitycsIcon
