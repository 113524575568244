import { memo } from 'react'
import PropTypes from 'prop-types'
import styles from './authorizedMainContent.module.scss'

const AuthorizedMainContent = ({ children }) => {
  return <div className={styles.mainContent}>{children}</div>
}

AuthorizedMainContent.propTypes = {
  children: PropTypes.node.isRequired,
}

export default memo(AuthorizedMainContent)
