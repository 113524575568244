// eslint-disable-next-line import/no-mutable-exports
let WORKFLOWS_API_URL = process.env.REACT_APP_WORKFLOWS_API_URL
// eslint-disable-next-line import/no-mutable-exports
let AUTH_API_URL = process.env.REACT_APP_AUTH_API_URL
// eslint-disable-next-line import/no-mutable-exports
let AUTH_BACKOFFICE_API_URL = process.env.REACT_APP_AUTH_BACKOFFICE_API_URL
// eslint-disable-next-line import/no-mutable-exports
let OFFICES_API_URL = process.env.REACT_APP_OFFICES_API_URL
// eslint-disable-next-line import/no-mutable-exports
let JMS_PBI_AUTH_API_URL = process.env.REACT_APP_JMS_PBI_AUTH_API_URL
// eslint-disable-next-line import/no-mutable-exports
let EXECUTORS_API_URL = process.env.REACT_APP_EXECUTORS_API_URL
// eslint-disable-next-line import/no-mutable-exports
let AKER_API_URL = process.env.REACT_APP_AKER_API_URL
// eslint-disable-next-line import/no-mutable-exports
let VEHICLE_API_URL = process.env.REACT_APP_VEHICLE_API_URL
// eslint-disable-next-line import/no-mutable-exports
let JMS_FILE_MANAGER_API_URL = process.env.REACT_APP_JMS_FILE_MANAGER_API_URL

const { REACT_APP_GOOGLE_MAPS_ROUTE_API_KEY } = process.env

export const MOCK_API_URL = 'https://private-f2efd7-witwot.apiary-mock.com'

if (process.env.NODE_ENV === 'development') {
  // WORKFLOWS_API_URL = 'https://workflows.witwot.digbang.com/backoffice/v1'
  // AUTH_API_URL = 'https://auth.witwot.digbang.com/auth/v1'
  // AUTH_BACKOFFICE_API_URL = 'https://auth.witwot.digbang.com/backoffice/v1'

  // ENTORNO UAT */
  WORKFLOWS_API_URL = 'https://workflows-uat.witwot.com/backoffice/v1'
  EXECUTORS_API_URL =
    'https://jms-witwot-executors-api-test.yellowsand-d8e659b2.brazilsouth.azurecontainerapps.io/api/v1'
  AUTH_API_URL = 'https://auth-uat.witwot.com/auth/v1'
  AUTH_BACKOFFICE_API_URL = 'https://auth-uat.witwot.com/backoffice/v1'
  OFFICES_API_URL =
    'https://jms-witwot-office-api-test.yellowsand-d8e659b2.brazilsouth.azurecontainerapps.io'
  AKER_API_URL =
    'https://jms-seg-satelital-api-test.yellowsand-d8e659b2.brazilsouth.azurecontainerapps.io/api/v1'
  VEHICLE_API_URL =
    'https://jms-vehicle-management-api-test.yellowsand-d8e659b2.brazilsouth.azurecontainerapps.io/api/v1'
  JMS_PBI_AUTH_API_URL =
    'https://jms-pbi-auth-api-test.yellowsand-d8e659b2.brazilsouth.azurecontainerapps.io/api/v1'
  JMS_FILE_MANAGER_API_URL =
    'https://jms-filemanager-api-test.yellowsand-d8e659b2.brazilsouth.azurecontainerapps.io/api/v1'

  /* ENTORNO DEV */
  // WORKFLOWS_API_URL = 'https://workflows-dev.witwot.com/backoffice/v1'
  // AUTH_API_URL = 'https://auth-dev.witwot.com/auth/v1'
  // AUTH_BACKOFFICE_API_URL = 'https://auth-dev.witwot.com/backoffice/v1'
  // OFFICES_API_URL =
  //   'https://jms-witwot-office-api-dev.purplemeadow-fcab966f.brazilsouth.azurecontainerapps.io'
  // EXECUTORS_API_URL =
  //   'https://jms-witwot-executors-api-dev.purplemeadow-fcab966f.brazilsouth.azurecontainerapps.io/api/v1'
  // AKER_API_URL =
  //   'https://jms-seg-satelital-api-dev.purplemeadow-fcab966f.brazilsouth.azurecontainerapps.io/api/v1'
  // VEHICLE_API_URL =
  //   'https://jms-vehicle-management-api-dev.purplemeadow-fcab966f.brazilsouth.azurecontainerapps.io/api/v1'
  // JMS_PBI_AUTH_API_URL =
  //   'https://jms-pbi-auth-api-dev.purplemeadow-fcab966f.brazilsouth.azurecontainerapps.io/api/v1'
  // JMS_FILE_MANAGER_API_URL =
  //   'https://jms-filemanager-api-dev.purplemeadow-fcab966f.brazilsouth.azurecontainerapps.io/api/v1'

  /* ENTORNO PRE-PROD */
  // WORKFLOWS_API_URL = 'https://workflows-preprod.witwot.com/backoffice/v1'
  // AUTH_API_URL = 'https://auth-preprod.witwot.com/auth/v1'
  // AUTH_BACKOFFICE_API_URL = 'https://auth-preprod.witwot.com/backoffice/v1'
  // OFFICES_API_URL =
  //   'https://jms-witwot-office-api-qa.nicemeadow-2f3b046b.brazilsouth.azurecontainerapps.io'
  // WORKFLOWS_API_URL = 'http://192.168.0.102:80/backoffice/v1'
  // AUTH_API_URL = 'http://192.168.0.102:80/auth/v1'
  // AUTH_BACKOFFICE_API_URL = 'http://192.168.0.102:80/backoffice/v1'
  // AKER_API_URL =
  //  'https://jms-seg-satelital-api-dev.purplemeadow-fcab966f.brazilsouth.azurecontainerapps.io/api/v1'
}

export const getDomain = () => {
  if (process.env.NODE_ENV === 'development') {
    // return 'foodservice-dev.witwot.com'
    return 'test-uat.witwot.com'
    // return 'test-preprod.witwot.com'
  }

  return window.location.host
}

export {
  AKER_API_URL,
  AUTH_API_URL,
  AUTH_BACKOFFICE_API_URL,
  EXECUTORS_API_URL,
  JMS_FILE_MANAGER_API_URL,
  JMS_PBI_AUTH_API_URL,
  OFFICES_API_URL,
  REACT_APP_GOOGLE_MAPS_ROUTE_API_KEY,
  VEHICLE_API_URL,
  WORKFLOWS_API_URL,
}
export default {
  WORKFLOWS_API_URL,
  AUTH_API_URL,
  AUTH_BACKOFFICE_API_URL,
  OFFICES_API_URL,
  REACT_APP_GOOGLE_MAPS_ROUTE_API_KEY,
  JMS_PBI_AUTH_API_URL,
  JMS_FILE_MANAGER_API_URL,
}
