export const LOGIN = '/login'
export const LOGOUT = '/logout'
export const DASHBOARD = '/'
export const USERSTRACKING = '/seguimiento-usuarios'
// export const FORGOT_PASSWORD = '/olvide-clave'
export const RESET_PASSWORD = '/generar-clave'
export const TRACKING = '/seguimiento'
export const TRACKING_MENU = '/seguimiento-menu'

// operators
export const OPERATORS_LIST = '/operadores'
export const CREATE_OPERATOR = '/operadores/crear'
export const EDIT_OPERATOR = '/operadores/:id/editar'
export const VIEW_OPERATOR = '/operadores/:id/ver'

// roles
export const ROLES_LIST = '/roles'
export const CREATE_ROLE = '/roles/crear'
export const EDIT_ROLE = '/roles/:id/editar'
export const VIEW_ROLE = '/roles/:id/ver'

// executors
export const EXECUTORS_LIST = '/ejecutores'
export const CREATE_EXECUTOR = '/ejecutores/crear'
export const EDIT_EXECUTOR = '/ejecutores/:id/editar'
export const VIEW_EXECUTOR = '/ejecutores/:id/ver'

// offices
export const OFFICES_LIST = '/oficinas'
export const CREATE_OFFICE = '/oficinas/crear'
export const EDIT_OFFICE = '/oficinas/:id/editar'
export const VIEW_OFFICE = '/oficinas/:id/ver'
export const ACCESS_LIST = '/acceso'

// work orders
export const WORK_ORDERS_LIST = '/servicios'
export const WORK_ORDER = '/servicios/:id'
export const WORK_ORDER_CREATE = '/servicios/crear'

// LIVE
export const LIVE_WORK_ORDERS = '/servicios-real-time'

// tasks
export const TASKS_LIST = '/tareas'
export const TASKS_ACCOUNTING_LIST = '/rendiciones'
export const TASK_ACCOUNTING = '/rendiciones/:id/:taskId'
export const TASK_DETAIL = '/tareas/:taskId'

// zones
export const ZONES = '/zonas'
export const CREATE_ZONE = '/zonas/crear'
export const EDIT_ZONE = '/zonas/:id/editar'
export const VIEW_ZONE = '/zonas/:id/ver'

// global parameters
export const GLOBAL_PARAMETERS = '/parametros-globales'
export const EDIT_GLOBAL_PARAMETERS = '/parametros-globales/editar'

// plannings
export const PLANNER = '/planificador'
export const PLANNINGS_LIST = '/planificaciones'
export const PLANNING = '/planificaciones/:id'
export const ASSIGNER = '/asignador'
export const ROUTER = '/ruteador'
export const ASSIGNER_PLANNING = '/asignador/:id'
export const ROUTING_PLANNING = '/ruteador/:id'

// clients
export const CLIENTS_LIST = '/clientes'
export const CREATE_CLIENT = '/clientes/crear'
export const EDIT_CLIENT = '/clientes/:id/editar'
export const VIEW_CLIENT = '/clientes/:id/ver'
export const CREATE_BRANCH = '/clientes/:clientId/domicilios/crear'
export const EDIT_BRANCH = '/clientes/:clientId/domicilios/:id/editar'
export const VIEW_BRANCH = '/clientes/:clientId/domicilios/:id/ver'

// clients
export const VEHICLES_LIST = '/vehiculos'
export const CREATE_VEHICLE = '/vehiculos/crear'
export const EDIT_VEHICLE = '/vehiculos/:id/editar'
export const VIEW_VEHICLE = '/vehiculos/:id/ver'

// Analytics
export const ANALYTICS_TASKS = '/analytics-tasks'
export const ANALYTICS_ACCOUNTING = '/analytics-accounting'

// dev
export const DEV_CREATE_WORK_ORDERS = '/crear-servicios'

// Gestion de Carga
export const LOAD_TYPES = '/tipo-cargas'
export const VEHICLES_TYPES = '/tipo-vehiculos'
export const VEHICLES = '/gestion-vehiculos'
export const SUPPLIERS = '/proveedores'

// ROUTE ORDER BY NAME :) !!
export default {
  ANALYTICS_TASKS,
  ANALYTICS_ACCOUNTING,
  CREATE_EXECUTOR,
  ACCESS_LIST,
  CREATE_OPERATOR,
  CREATE_ROLE,
  DASHBOARD,
  USERSTRACKING,
  EDIT_EXECUTOR,
  EDIT_OFFICE,
  EDIT_OPERATOR,
  EDIT_ROLE,
  EXECUTORS_LIST,
  LOGIN,
  LOGOUT,
  OFFICES_LIST,
  CREATE_OFFICE,
  OPERATORS_LIST,
  SUPPLIERS,
  VEHICLES,
  ROLES_LIST,
  VIEW_EXECUTOR,
  VIEW_OFFICE,
  VIEW_OPERATOR,
  VIEW_ROLE,
  WORK_ORDERS_LIST,
  TASK_DETAIL,
}
