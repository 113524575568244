import { memo } from 'react'
import c from 'classnames'
import PropTypes from 'prop-types'
// import ArrowIcon from 'assets/icons/ArrowIcon'
// import { NavLink } from 'react-router-dom'
import styles from './pageTitle.module.scss'

const PageTitle = ({ title, breadcrumb, rightSection, headerType /* , goBack */ }) => {
  return (
    <div className={c(styles.pageTitleContainer, headerType && styles.headerType)}>
      <div className={styles.titleContainer}>
        {/* {goBack && ( */}
        {/*  <NavLink to={goBack}> */}
        {/*    <span className={styles.goBackIcon}> */}
        {/*      <ArrowIcon size={23} type="left" /> */}
        {/*    </span> */}
        {/*  </NavLink> */}
        {/* )} */}
        <div className={c(styles.sectionTitle, headerType && styles.sectionTitleHeaderType)}>
          {title}
        </div>
        {breadcrumb.map((breadcrumbItem, i) => (
          <div
            className={c(
              styles.breadcrumbItem,
              i === breadcrumb.length - 1 && styles.activeBreadcrumbItem
            )}
            key={breadcrumbItem.label}
          >
            <span>/</span>
            <span className={styles.breadcrumbLabel}>{breadcrumbItem.label}</span>
          </div>
        ))}
      </div>
      {rightSection && <div className={styles.rightSection}>{rightSection}</div>}
    </div>
  )
}

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
  rightSection: PropTypes.node,
  // goBack: PropTypes.string,
  breadcrumb: PropTypes.oneOfType([PropTypes.array]),
  headerType: PropTypes.bool,
}

PageTitle.defaultProps = {
  rightSection: null,
  // goBack: null,
  breadcrumb: [],
  headerType: false,
}

export default memo(PageTitle)
