import i18n from 'i18next'
import branches from 'lang/branches.json'
import access from 'lang/access.json'
import clients from 'lang/clients.json'
import common from 'lang/common.json'
import createWorkOrders from 'lang/createWorkOrders.json'
import errors from 'lang/errors.json'
import executors from 'lang/executors.json'
import globalParameters from 'lang/globalParameters.json'
import header from 'lang/header.json'
import loadTypes from 'lang/loadTypes.json'
import map from 'lang/map.json'
import offices from 'lang/offices.json'
import operators from 'lang/operators.json'
import orders from 'lang/orders.json'
import permissions from 'lang/permissions.json'
import plannings from 'lang/plannings.json'
import polygon from 'lang/polygon.json'
import roles from 'lang/roles.json'
import suppliers from 'lang/suppliers.json'
import tasks from 'lang/tasks.json'
import vehicles from 'lang/vehicles.json'
import workOrders from 'lang/workOrders.json'
import zones from 'lang/zones.json'
import vehicleTypes from 'lang/vehicleTypes.json'
import { initReactI18next } from 'react-i18next'

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  lng: 'en',

  // have a common namespace used around the full app
  ns: [
    'common',
    'clients',
    'branches',
    'access',
    'permissions',
    'header',
    'operators',
    'roles',
    'executors',
    'workOrders',
    'offices',
    'tasks',
    'errors',
    'map',
    'createWorkOrders',
    'globalParameters',
    'orders',
    'plannings',
    'suppliers',
    'polygon',
    'loadTypes',
    'vehicles',
    'zones',
    'vehicleTypes',
  ],
  defaultNS: 'common',

  debug: true,

  interpolation: {
    escapeValue: false, // not needed for react!!
  },

  react: {
    useSuspense: true,
  },

  resources: {
    en: {
      common,
      clients,
      branches,
      access,
      permissions,
      header,
      operators,
      roles,
      executors,
      workOrders,
      offices,
      tasks,
      errors,
      map,
      globalParameters,
      createWorkOrders,
      orders,
      loadTypes,
      polygon,
      plannings,
      vehicles,
      zones,
      suppliers,
      vehicleTypes,
    },
  },
})

export default i18n
