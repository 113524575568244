import { Suspense, useContext } from 'react'
import { Route, Routes, BrowserRouter } from 'react-router-dom'
import { observer } from 'mobx-react'
import MainLayout, { Gated, LoadingRing, PrivateRoute, StoreContext } from 'subFramework'
import AuthorizedMainContent from 'presentation/AuthorizedMainContent'
import AuthorizedNavBar from 'presentation/AuthorizedNavBar'
import AuthorizedHeader from 'presentation/AuthorizedHeader'
import Forbidden403 from 'scenes/Forbidden403'
import AuthPreLoaders from 'presentation/AuthPreLoaders'
import PreLoaders from 'presentation/PreLoaders'
import routes from './routes'
import { LOGIN } from './paths'

const Router = () => {
  const { authStore } = useContext(StoreContext)

  return (
    <BrowserRouter>
      <Suspense fallback={<LoadingRing center />}>
        <PreLoaders>
          <Routes>
            {routes.publicRoutes.map((route) => (
              <Route
                element={route.component}
                exact={route.exact}
                path={route.path}
                key={route.path}
                {...route}
              />
            ))}
            {routes.privateRoutes.map((route) => (
              <Route
                element={
                  <PrivateRoute
                    isAuthenticated={authStore.isAuthenticated}
                    redirectPath={LOGIN}
                    authStore={authStore}
                  >
                    <Gated permission={route.permission} forbiddenPage={<Forbidden403 />}>
                      <AuthPreLoaders>
                        <MainLayout
                          sidebar={AuthorizedNavBar}
                          header={AuthorizedHeader}
                          mainContent={AuthorizedMainContent}
                        >
                          {route.component}
                        </MainLayout>
                      </AuthPreLoaders>
                    </Gated>
                  </PrivateRoute>
                }
                exact={route.exact}
                path={route.path}
                key={route.path}
                {...route}
              />
            ))}
          </Routes>
        </PreLoaders>
      </Suspense>
    </BrowserRouter>
  )
}

export default observer(Router)
