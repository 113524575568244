/* eslint-disable no-param-reassign */
import axios from 'axios'
import { getDomain } from 'services/config'

const axiosInterceptors = (rootStore) => {
  // Handles all 403 requests
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (
        error.response !== undefined &&
        error.response.status !== 403 &&
        error.response.status !== 422
      ) {
        error.response.unknown = true
      }

      return Promise.reject(error)
    }
  )

  // Handles all 401 requests
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        rootStore.authStore.logout()
      }

      return Promise.reject(error)
    }
  )

  // Adds bearer to all API requests
  axios.interceptors.request.use(
    (config) => {
      if (rootStore.authStore.isAuthenticated) {
        config.headers.Authorization = `Bearer ${rootStore.authStore.authUser.token}`
        config.headers['X-Tenant'] = rootStore.authStore.authUser?.tenant?.id
      }

      config.headers['X-Domain'] = getDomain()
      config.headers['accept-language'] = 'es'
      config.headers.Accept = 'application/json'

      // Do something before request is sent
      return config
    },
    (error) => Promise.reject(error)
  )

  // Token update
  axios.interceptors.response.use(
    (response) => {
      if (response && response.data && response.data.metadata && response.data.metadata.token) {
        // updates jwtoken if needed
        rootStore.authStore.updateToken(response.data.metadata.token.token)
      }

      return response
    },
    (error) => Promise.reject(error)
  )
}

export default axiosInterceptors
