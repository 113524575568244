import { memo, useCallback, useEffect, useState } from 'react'
import c from 'classnames'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import styles from './mainLayout.module.scss'

const MainLayout = ({ sidebar: Sidebar, header: Header, mainContent: MainContent, children }) => {
  const [visibleMobileSideBar, setVisibleMobileSideBar] = useState(false)
  const [collapsedSideBar, setCollapsedSideBar] = useState(false)

  const location = useLocation()

  useEffect(() => {
    setTimeout(() => setVisibleMobileSideBar(false), 100)
  }, [location])

  const handleClickMenu = useCallback(
    () => setVisibleMobileSideBar(!visibleMobileSideBar),
    [visibleMobileSideBar]
  )

  const handleClickCollapse = useCallback(
    () => setCollapsedSideBar(!collapsedSideBar),
    [collapsedSideBar]
  )

  return (
    <div className={c(styles.wrapper, collapsedSideBar && styles.collapsedSideBar)}>
      <header className={styles.header}>
        <Header handleClickMenu={handleClickMenu} />
      </header>
      <div className={c(styles.overlay, visibleMobileSideBar && styles.visibleOverlay)} />
      <nav className={c(styles.sidebar, visibleMobileSideBar && styles.visibleMobileSideBar)}>
        <Sidebar
          handleClickMenu={handleClickMenu}
          collapsed={collapsedSideBar}
          handleClickCollapse={handleClickCollapse}
        />
      </nav>
      <main className={styles.main}>
        <MainContent>{children}</MainContent>
      </main>
    </div>
  )
}

MainLayout.propTypes = {
  sidebar: PropTypes.oneOfType([PropTypes.any]).isRequired,
  header: PropTypes.oneOfType([PropTypes.object]).isRequired,
  mainContent: PropTypes.oneOfType([PropTypes.any]).isRequired,
  children: PropTypes.node.isRequired,
}

export default memo(MainLayout)
