/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import ArrowIcon from 'assets/icons/ArrowIcon'
import c from 'classnames'
import PropTypes from 'prop-types'
import { memo } from 'react'
import ReactTooltip from 'react-tooltip'
import { Gated } from 'subFramework'
import { generateUniqueId } from 'util/utils'
import styles from './menuButton.module.scss'

const Content = ({ icon, collapsed, label, isSubMenuOpen, dropdownItems }) => {
  return (
    <>
      {collapsed && (
        <ReactTooltip
          id={`${label}tooltip`}
          className={styles.tooltip}
          arrowColor="transparent"
          effect="solid"
        >
          {label}
        </ReactTooltip>
      )}
      <span data-tip data-for={`${label}tooltip`}>
        {icon}
      </span>
      <span className={styles.label}>{label}</span>
      <div className={styles.iconWrapper}>
        {dropdownItems && <ArrowIcon type={isSubMenuOpen ? 'down' : 'up'} size={15} />}
      </div>
    </>
  )
}

const MenuButton = ({
  icon,
  label,
  to,
  collapsed,
  permission,
  subMenu,
  dropdownItems,
  isSubMenuOpen,
  handleSubMenuToggle,
  closeSubMenu,
}) => {
  return (
    <div className={styles.menuButtonWrapper}>
      {to ? (
        <Gated.NavLink
          className={(navData) =>
            c(
              styles.menuButton,
              subMenu && styles.subMenuButton,
              navData.isActive ? styles.menuButtonActive : 'none',
              collapsed && styles.collapsed
            )
          }
          permission={permission}
          to={to}
          onClick={closeSubMenu}
        >
          <Content icon={icon} collapsed={collapsed} label={label} />
        </Gated.NavLink>
      ) : (
        <Gated permission={permission}>
          <div
            className={c(
              styles.menuButton,
              subMenu && styles.subMenuButton,
              collapsed && styles.collapsed
            )}
            role="button"
            tabIndex={0}
            onClick={handleSubMenuToggle}
          >
            <Content
              icon={icon}
              collapsed={collapsed}
              label={label}
              isSubMenuOpen={isSubMenuOpen}
              dropdownItems={dropdownItems}
            />
          </div>
        </Gated>
      )}

      {isSubMenuOpen && (
        <div className={styles.subMenu}>
          {dropdownItems.map((item, index) => (
            <MenuButton
              key={generateUniqueId(index)}
              icon={item.icon}
              label={item.label}
              to={item.to}
              collapsed={item.collapsed}
              permission={item.permission}
              subMenu={item.subMenu}
            />
          ))}
        </div>
      )}
    </div>
  )
}

Content.propTypes = {
  icon: PropTypes.node,
  label: PropTypes.string,
  collapsed: PropTypes.bool,
  isSubMenuOpen: PropTypes.bool,
  dropdownItems: PropTypes.bool,
}

Content.defaultProps = {
  icon: null,
  label: null,
  collapsed: false,
  isSubMenuOpen: false,
  dropdownItems: false,
}

MenuButton.propTypes = {
  icon: PropTypes.node,
  label: PropTypes.string,
  collapsed: PropTypes.bool,
  to: PropTypes.string,
  permission: PropTypes.string,
  isSubMenuOpen: PropTypes.bool,
  handleSubMenuToggle: PropTypes.func,
  closeSubMenu: PropTypes.func,
  subMenu: PropTypes.bool,
  dropdownItems: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.node,
      label: PropTypes.string,
      to: PropTypes.string,
      onClick: PropTypes.func,
      collapsed: PropTypes.bool,
    })
  ),
}

MenuButton.defaultProps = {
  icon: null,
  label: null,
  to: null,
  subMenu: false,
  collapsed: false,
  permission: 'yes',
  dropdownItems: [],
  isSubMenuOpen: false,
  handleSubMenuToggle: () => null,
  closeSubMenu: () => null,
}

export default memo(MenuButton)
