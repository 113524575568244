import axios from 'axios'
import AuthUser from 'models/AuthUser'
import { AUTH_API_URL } from 'services/config'
import { AuthService as AuthServiceSubFramework } from 'subFramework'

class AuthService extends AuthServiceSubFramework {
  getAuthUserFromJson = (data) => AuthUser.fromJson(data)

  getAuthUserFromCookie = (data) => AuthUser.fromCookie(data)

  getAuthenticateUri = () => `${AUTH_API_URL}/authentication/authenticate`

  getLogoutUri = () => `${AUTH_API_URL}/authentication/logout`

  getAuthorizationUri = () => `${AUTH_API_URL}/authorization`

  me = () => {
    return axios.get(`${AUTH_API_URL}/me`).then((response) => {
      return AuthUser.fromJson({ user: response.data })
    })
  }

  getTenantFeature = async (feature) => {
    const endpoint = this.getAuthorizationUri()
    try {
      const tenantPermissions = await axios.get(`${endpoint}/tenants-features?feature=${feature}`)
      return tenantPermissions.data.data[`${feature}`]
    } catch (e) {
      return e
    }
  }
}

export default AuthService
