import { action, computed, makeObservable, observable } from 'mobx'
import { InputStore } from 'subFramework'
import i18n from 'i18next'

const convertToGroupedOptions = (permissionOptions) => {
  const partialGroupedOptions = {}

  permissionOptions.forEach((option) => {
    const type = option.idName.split('.')[0]
    if (partialGroupedOptions[type]) {
      partialGroupedOptions[type].push(option)
    } else {
      partialGroupedOptions[type] = [option]
    }
  })

  return partialGroupedOptions
}

export default class OptionsBoxStore {
  constructor(options) {
    this.filteredGroupedOptions = {}
    this.searchValue = new InputStore()
    this.groupStatus = {}
    this.setOptions(options)

    this.makeObservable()
  }

  optionsIdsByGroup(group) {
    return this.groupedOptions[group].map((option) => option.id)
  }

  toggleGroupStatus(group) {
    this.groupStatus[group].setValue(!this.groupStatus[group].value)
  }

  setOptions(options) {
    this.options = options
    this.filterGroupedOptions()
    Object.keys(this.groupedOptions).forEach((group) => {
      if (!this.groupStatus[group]) {
        this.groupStatus[group] = new InputStore(null, false)
      }
    })
  }

  changeSearchValue(value) {
    this.searchValue.setValue(value)
    this.filterGroupedOptions()
  }

  get groups() {
    return Object.keys(this.groupedOptions)
  }

  filterGroupedOptions() {
    if (this.searchValue.value) {
      const value = this.searchValue.value.toLowerCase()
      const newGroupedOptions = {}
      this.groups.forEach((group) => {
        if (i18n.t(`permissions:${group}`).toLowerCase().includes(value)) {
          newGroupedOptions[group] = this.groupedOptions[group]
        } else {
          this.groupedOptions[group].forEach((item) => {
            if (item.name.toLowerCase().includes(value)) {
              if (newGroupedOptions[group]) {
                newGroupedOptions[group].push(item)
              } else {
                newGroupedOptions[group] = [item]
              }
            }
          })
        }
      })

      this.filteredGroupedOptions = newGroupedOptions
    } else {
      this.filteredGroupedOptions = this.groupedOptions
    }
  }

  get groupedOptions() {
    return convertToGroupedOptions(this.options)
  }

  makeObservable() {
    makeObservable(this, {
      // Observables
      options: observable,
      filteredGroupedOptions: observable,
      groupStatus: observable,
      // Actions
      filterGroupedOptions: action,
      changeSearchValue: action,
      setOptions: action,
      // computed
      groupedOptions: computed,
    })
  }
}
