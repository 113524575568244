import React from 'react'
import c from 'classnames'
import PropTypes from 'prop-types'
import styles from './operatorIcon.module.scss'

const OperatorIcon = ({ className, size }) => {
  return (
    <svg
      width={size}
      className={c(styles.icon, className)}
      viewBox="2 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.469 15.406A.455.455 0 0 1 5 14.937v-1c0-.364.094-.695.281-.992.188-.297.448-.518.782-.664.76-.333 1.445-.573 2.054-.719A8.063 8.063 0 0 1 10 11.345c.646 0 1.27.073 1.875.219.604.145 1.287.385 2.047.718A1.784 1.784 0 0 1 15 13.937v1a.455.455 0 0 1-.469.47H5.47zm10.125 0a.39.39 0 0 0 .25-.164.569.569 0 0 0 .094-.336v-.969c0-.656-.167-1.195-.5-1.617a3.659 3.659 0 0 0-1.313-1.023 15.48 15.48 0 0 1 2.031.367c.636.162 1.151.346 1.547.555.344.198.615.443.813.734.198.292.297.62.297.985v1a.455.455 0 0 1-.47.468h-2.75zM10 10.391c-.688 0-1.25-.22-1.688-.657-.437-.437-.656-1-.656-1.687 0-.688.219-1.25.657-1.688.437-.437 1-.656 1.687-.656.688 0 1.25.219 1.688.656.437.438.656 1 .656 1.688 0 .687-.219 1.25-.656 1.687-.438.438-1 .657-1.688.657zm5.625-2.344c0 .687-.219 1.25-.656 1.687-.438.438-1 .657-1.688.657-.114 0-.242-.008-.383-.024a1.655 1.655 0 0 1-.382-.086c.25-.26.44-.58.57-.96.13-.38.195-.805.195-1.274s-.065-.883-.195-1.242c-.13-.36-.32-.69-.57-.992a3.298 3.298 0 0 1 .765-.11c.688 0 1.25.219 1.688.656.437.438.656 1 .656 1.688zm-9.688 6.422h8.125v-.531a.894.894 0 0 0-.148-.485.817.817 0 0 0-.367-.328c-.75-.333-1.38-.557-1.89-.672A7.556 7.556 0 0 0 10 12.281c-.594 0-1.148.057-1.664.172-.516.115-1.149.339-1.899.672a.775.775 0 0 0-.359.328.93.93 0 0 0-.14.485v.53zM10 9.453c.406 0 .742-.133 1.008-.398.265-.266.398-.602.398-1.008s-.133-.742-.398-1.008c-.266-.266-.602-.398-1.008-.398s-.742.132-1.008.398c-.265.266-.398.602-.398 1.008s.133.742.398 1.008c.266.265.602.398 1.008.398z"
        fill="#454545"
      />
    </svg>
  )
}

OperatorIcon.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number,
}

OperatorIcon.defaultProps = {
  className: null,
  size: 20,
}

export default OperatorIcon
