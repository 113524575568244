import React from 'react'
import c from 'classnames'
import PropTypes from 'prop-types'
import styles from './workOrderIcon.module.scss'

const WorkOrderIcon = ({ className, size }) => {
  return (
    <svg
      className={c(styles.icon, className)}
      width={size}
      viewBox="-5 -7 25 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.90891 9.91645L2.95073 7.95827L2.18164 8.72736L4.90891 11.4546L9.818 6.54554L9.04891 5.771L4.90891 9.91645Z"
        fill="#454545"
      />
      <path
        d="M10.9091 1.63636H9.27273V1.09091C9.27273 0.801582 9.15779 0.524105 8.95321 0.31952C8.74862 0.114935 8.47115 0 8.18182 0H3.81818C3.52885 0 3.25138 0.114935 3.04679 0.31952C2.84221 0.524105 2.72727 0.801582 2.72727 1.09091V1.63636H1.09091C0.801582 1.63636 0.524105 1.7513 0.31952 1.95588C0.114935 2.16047 0 2.43795 0 2.72727V14.1818C0 14.4711 0.114935 14.7486 0.31952 14.9532C0.524105 15.1578 0.801582 15.2727 1.09091 15.2727H10.9091C11.1984 15.2727 11.4759 15.1578 11.6805 14.9532C11.8851 14.7486 12 14.4711 12 14.1818V2.72727C12 2.43795 11.8851 2.16047 11.6805 1.95588C11.4759 1.7513 11.1984 1.63636 10.9091 1.63636ZM3.81818 1.09091H8.18182V3.27273H3.81818V1.09091ZM10.9091 14.1818H1.09091V2.72727H2.72727V4.36364H9.27273V2.72727H10.9091V14.1818Z"
        fill="#454545"
      />
    </svg>
  )
}

WorkOrderIcon.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number,
}

WorkOrderIcon.defaultProps = {
  className: null,
  size: 20,
}

export default WorkOrderIcon
