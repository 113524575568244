import TaskType from 'models/TaskType'
import TableColumn from 'models/TableColumn'
import SelectOption from 'models/SelectOption'

// provisional fix
const NOT_CREATABLE_TYPES = ['local-pickup', 'correo-chile-local-pickup', 'mailing-local-pickup']

class Settings {
  constructor(taskTypes = [], tasksListColumns = [], refundReasons = []) {
    this.taskTypes = taskTypes
    this.tasksListColumns = tasksListColumns
    this.refundReasons = refundReasons
  }

  get creatableTaskTypes() {
    return this.taskTypes.filter((type) => !NOT_CREATABLE_TYPES.includes(type?.id))
  }

  static fromJson({ taskTypes = [], taskList = [], refundReasons = [] }) {
    return new Settings(
      taskTypes.map((taskType) => TaskType.fromJson(taskType)),
      taskList.map((column) => TableColumn.fromJson(column)),
      refundReasons.map((refundReason) => SelectOption.fromJson(refundReason))
    )
  }
}

export default Settings
