import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Button, Gated } from 'subFramework'
import { DASHBOARD } from 'routing/paths'
import * as permissions from 'routing/permissions'
import AuthorizedNavBar from 'presentation/AuthorizedNavBar'
import AuthorizedHeader from 'presentation/AuthorizedHeader'
import AuthorizedMainContent from 'presentation/AuthorizedMainContent'
import MainLayout from 'subFramework/presentation/MainLayout'
import styles from './forbidden403.module.scss'

const Forbidden403 = () => {
  const { t } = useTranslation()

  return (
    <MainLayout
      sidebar={AuthorizedNavBar}
      header={AuthorizedHeader}
      mainContent={AuthorizedMainContent}
    >
      <div className={styles.errorContainer}>
        <div className={styles.title}>{t('forbidden403')}</div>
        <div className={styles.text}>{t('403ForbiddenError')}</div>
        <div>
          <Gated permission={permissions.VIEW_MAP}>
            <Button label={t('backToHome')} as={Link} to={DASHBOARD} />
          </Gated>
        </div>
      </div>
    </MainLayout>
  )
}

export default Forbidden403
