class DynamicTableColumn {
  constructor(
    title,
    // eslint-disable-next-line default-param-last
    key,
    size,
    dataAccessor = null,
    titleAccessor = null,
    sortable = true,
    customTitleAlt = null,
    actionsColumn = false,
    mainColumn = false,
    displayLabel = true,
    displayColumn = true,
    rightColumn = false,
    fixedColumn = null
  ) {
    this.title = title
    this.key = key
    this.size = size
    this.dataAccessor = dataAccessor
    this.titleAccesor = titleAccessor
    this.sortable = sortable
    this.customTitleAlt = customTitleAlt
    this.actionsColumn = actionsColumn
    this.mainColumn = mainColumn
    this.displayLabel = displayLabel
    this.displayColumn = displayColumn
    this.rightColumn = rightColumn
    this.fixedColumn = fixedColumn
  }
}

export default DynamicTableColumn
