import React from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import styles from './dualListBox.module.scss'
import OptionsBox from './OptionsBox'
import FormLabel from '../FormLabel'

const DualListBoxComponent = ({ store, onChange, options, literal }) => {
  const { t } = useTranslation(['errors', 'roles'])
  const hasError = store.error

  // const optionsInactive = options.filter(
  //   (option) => !(store.value.filter((selected) => selected.id === option.id).length > 0)
  // )

  const handleSelectItems = (items) => {
    onChange(items)
  }

  return (
    <div className={styles.content}>
      <FormLabel label={t('roles:permissionsLabel')} literal={literal} />
      <div className={styles.listBox}>
        <OptionsBox
          options={options}
          literal={literal}
          label={t('roles:roleDisabled')}
          selectedItems={store.value}
          onChange={handleSelectItems}
          hasError={hasError}
        />
        {/* <div className={styles.actionsWrapperItems}> */}
        {/*  <div className={styles.actionsWrapper}> */}
        {/*    <ActionButton */}
        {/*      onClick={moveAllItemsToInactive} */}
        {/*      literal={literal} */}
        {/*      icon={<LeftDoubleIcon />} */}
        {/*    /> */}
        {/*    <ActionButton onClick={handleClickLeft} literal={literal} icon={<LeftIcon />} /> */}
        {/*    <ActionButton onClick={handleClickRight} literal={literal} icon={<RightIcon />} /> */}
        {/*    <ActionButton */}
        {/*      onClick={moveAllItemsToActive} */}
        {/*      literal={literal} */}
        {/*      icon={<RightDoubleIcon />} */}
        {/*    /> */}
        {/*  </div> */}
        {/* </div> */}
        {/* <OptionsBox */}
        {/*  options={store.value} */}
        {/*  literal={literal} */}
        {/*  label={t('roles:roleActive')} */}
        {/*  onChange={handleSelectItem} */}
        {/*  hasError={hasError} */}
        {/*  selectedItem={selectedItem} */}
        {/* /> */}
      </div>
      {store.error && <div className={styles.labelError}>{t(`${store.errorMessage}`)}</div>}
    </div>
  )
}

DualListBoxComponent.propTypes = {
  store: PropTypes.oneOfType([PropTypes.object]).isRequired,
  literal: PropTypes.bool,
  options: PropTypes.oneOfType([PropTypes.array]).isRequired,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

DualListBoxComponent.defaultProps = {
  literal: false,
  error: null,
}

export default observer(DualListBoxComponent)
