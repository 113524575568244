import React from 'react'
import c from 'classnames'
import PropTypes from 'prop-types'
import styles from './realTimeWorkOrderIcon.module.scss'

const RealTimeWorkOrderIcon = ({ className, size }) => {
  return (
    <svg
      className={c(styles.icon, className)}
      width={size}
      viewBox="0 0 20 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.953 14.844h4.094a.455.455 0 0 0 .469-.469.455.455 0 0 0-.47-.469H7.954a.455.455 0 0 0-.469.469.455.455 0 0 0 .47.469zm0-2.656h4.094a.455.455 0 0 0 .469-.47.455.455 0 0 0-.47-.468H7.954a.455.455 0 0 0-.469.469.455.455 0 0 0 .47.469zM5.937 17.5a.9.9 0 0 1-.656-.281.9.9 0 0 1-.281-.657V5.938a.9.9 0 0 1 .281-.656A.9.9 0 0 1 5.938 5h5.25a.976.976 0 0 1 .672.281l2.859 2.86a.974.974 0 0 1 .281.671v7.75a.9.9 0 0 1-.281.657.9.9 0 0 1-.656.281H5.937zm5.172-9.125V5.937H5.938v10.625h8.125V8.845h-2.485a.455.455 0 0 1-.469-.469zM5.938 5.937v2.907-2.906 10.625V5.938z"
        fill="#fff"
      />
    </svg>
  )
}

RealTimeWorkOrderIcon.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number,
}

RealTimeWorkOrderIcon.defaultProps = {
  className: null,
  size: 20,
}

export default RealTimeWorkOrderIcon
