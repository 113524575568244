import c from 'classnames'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { memo, useCallback } from 'react'
import Paginator from 'subFramework/stores/Paginator'
import Page from './Page'
import styles from './tablePagination.module.scss'

const TablePagination = ({
  handlePageChange,
  paginator,
  maxPagesAtOnce,
  isLoading,
  prevIcon,
  nextIcon,
  displayResultsMessage,
}) => {
  const { currentPage, totalPages } = paginator

  const handleClickPage = useCallback(
    (page) => {
      if (!isLoading) {
        handlePageChange(page)
      }
    },
    [isLoading]
  )

  let fromPage = 1
  let toPage = totalPages

  if (totalPages > maxPagesAtOnce) {
    const halfPages = Math.floor(maxPagesAtOnce / 2)

    fromPage = Math.max(1, currentPage - halfPages)
    toPage = Math.min(totalPages, fromPage + maxPagesAtOnce - 1)
    if (toPage - fromPage + 1 < maxPagesAtOnce) {
      fromPage = Math.max(1, toPage - maxPagesAtOnce + 1)
    }
  }

  const pages = Array.from({ length: toPage - fromPage + 1 }, (_, index) => fromPage + index)

  return (
    <div className={c(styles.paginatorContainer)}>
      {(displayResultsMessage || isLoading) && (
        <div className={c(styles.resultsData, isLoading && styles.loadingResultsData)}>
          {isLoading ? null : displayResultsMessage}
        </div>
      )}
      {paginator.totalPages > 1 && (
        <ol className={styles.tablePagination}>
          {currentPage > 1 && (
            <Page
              handleClickPage={handleClickPage}
              page={currentPage - 1}
              pageLabel={prevIcon}
              isLoading={isLoading}
            />
          )}
          {pages[0] > 1 && (
            <>
              {pages[0] > 2 && (
                <Page
                  handleClickPage={handleClickPage}
                  page={1}
                  pageLabel={1}
                  isLoading={isLoading}
                />
              )}
              <Page
                handleClickPage={handleClickPage}
                page={pages[0] - 1}
                pageLabel="..."
                isLoading={isLoading}
              />
            </>
          )}
          {pages.map((page) => (
            <Page
              key={`pager_${page}`}
              handleClickPage={handleClickPage}
              page={page}
              active={page === currentPage}
              pageLabel={page}
              isLoading={isLoading}
            />
          ))}
          {toPage < totalPages && (
            <>
              <Page
                handleClickPage={handleClickPage}
                page={toPage + 1}
                pageLabel="..."
                isLoading={isLoading}
              />

              {toPage + 1 < totalPages && (
                <Page
                  handleClickPage={handleClickPage}
                  page={totalPages}
                  pageLabel={totalPages}
                  isLoading={isLoading}
                />
              )}
            </>
          )}
          {currentPage < totalPages && (
            <Page
              handleClickPage={handleClickPage}
              page={currentPage + 1}
              pageLabel={nextIcon}
              isLoading={isLoading}
            />
          )}
        </ol>
      )}
    </div>
  )
}

TablePagination.propTypes = {
  handlePageChange: PropTypes.func.isRequired,
  paginator: PropTypes.instanceOf(Paginator).isRequired,
  prevIcon: PropTypes.node,
  isLoading: PropTypes.bool,
  nextIcon: PropTypes.node,
  maxPagesAtOnce: PropTypes.number,
  displayResultsMessage: PropTypes.string,
}

TablePagination.defaultProps = {
  maxPagesAtOnce: 3,
  prevIcon: '<',
  nextIcon: '>',
  isLoading: false,
  displayResultsMessage: null,
}

export default memo(observer(TablePagination))
